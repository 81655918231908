import { maxLengths, validate } from './validate'

export const errorMessages = {
  streetAddress: (streetAddress: string) => {
    if (!validate.required(streetAddress)) return 'Required'
    if (!validate.streetAddress(streetAddress)) return 'Maximum character limit of 30 reached'
  },
  phone: (phone: string) => {
    if (!validate.phoneNumber(phone)) return 'Invalid phone number'
  },
  firstName: (firstName: string) => {
    if (!validate.maxLength(firstName, maxLengths.name)) return 'Maximum character limit of 30 reached'
  },
  lastName: (lastName: string) => {
    if (!validate.minLength(lastName, 2)) return 'Minimum character limit of 2'
    if (!validate.maxLength(lastName, maxLengths.name)) return 'Maximum character limit of 30 reached'
  },
  bizDescription: (description: string) => {
    if (!validate.description(description)) return 'Enter at least 16 characters'
  },
  birthdate: (birthdate: string) => {
    if (!validate.isValidDate(birthdate)) return 'Invalid date'
    if (!validate.isNotFutureDate(birthdate)) return 'Cannot be in the future'
    if (!validate.isOver18(birthdate)) return 'Must be over 18'
    if (!validate.isAfter1900(birthdate)) return 'Must be after 1900'
  },
  suite: (suite: string) => {
    if (!suite) return ''
    if (!validate.suite(suite)) return 'Maximum character limit of 30 reached'
  },
  city: (city: string) => {
    if (!validate.required(city)) return 'Required'
    if (!validate.city(city)) return 'Maximum character limit of 30 reached'
  },
  zipCode: (zipCode: string) => {
    if (!validate.required(zipCode)) return 'Required'
    if (!validate.zipCode(zipCode)) return "'Enter a 5-digit ZIP code'"
  },

  businessName: (businessName: string) => {
    if (!validate.required(businessName)) return 'Required'
    if (!validate.minLength(businessName, 2)) return "'Enter at least 2 characters'"
    if (!validate.businessName(businessName)) return 'Maximum character limit of 50'
  },
  dba: (dba: string | undefined) => (!validate.dba(dba) ? 'Maximum character limit of 50' : ''),
  pastDate: (date: string) => {
    if (!validate.required(date)) return 'Required'
    if (!validate.isValidDate(date)) return 'Invalid date'
    if (!validate.isNotFutureDate(date)) return 'Cannot be in the future'
  },
  futureDate: (date: string) => {
    if (!validate.required(date)) return 'Required'
    if (!validate.isValidDate(date)) return 'Invalid date'
    if (!validate.isFutureDate(date)) return 'Must be in the future'
  },
}
