import React from 'react'
import { Text } from '@northone/ui'

export const PageHeading: React.FC = ({ children }) => {
  return (
    <Text sx={{ margin: ['15px 0', '25px 0', '25px 0'] }} tag={['h3Serif', 'h2', 'h2']}>
      {children}
    </Text>
  )
}
