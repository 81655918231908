import { AnimatePresence, Button, ButtonTypeEnum, ErrorBanner, FlexColumnLayout, Text, theme } from '@northone/ui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Image } from 'rebass'
import { APP_CONTAINER_ID } from '../../app'
import { images } from '../../assets/images'
import { submitManualReviewApplication } from '../../core/application-review'
import { ManualReviewRequestBody } from '../../core/application-review/models/manual-review'
import { FormPanel, FORM_BUTTON_WIDTH } from '../../layouts/form'
import { reduxActions } from '../../redux/actions'
import { getFieldsAreValid, getManualReviewRequestBody } from '../../redux/selector'
import { IRootState } from '../../redux/state'

export const SubmitBlock: React.FC = () => {
  const dispatch = useDispatch()
  const fieldsAreValid = useSelector<IRootState, boolean>(getFieldsAreValid)
  const pathParams = window.location.pathname.split('/').filter(v => v)
  const businessId = pathParams[pathParams.indexOf('businesses') + 1]
  const applicationId = pathParams[pathParams.indexOf('applications') + 1]
  const requestBody = useSelector<IRootState, ManualReviewRequestBody | null>(getManualReviewRequestBody)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const submitApplication = async () => {
    if (!requestBody) {
      setHasError(true)
      return
    }
    try {
      setIsLoading(true)
      setHasError(false)

      await submitManualReviewApplication({ businessId, applicationId, requestBody })
      dispatch(reduxActions.finishSubmit())
    } catch {
      setHasError(true)
    }
    setIsLoading(false)
  }

  return (
    <FormPanel sx={{ marginTop: 36 }}>
      <AnimatePresence animation="fadeAndExpand">
        {hasError && <ErrorBanner text="Oops! Something went wrong. Please try again." />}
      </AnimatePresence>
      <Box sx={{ display: 'flex', flexDirection: ['column-reverse', 'column-reverse', 'row'] }}>
        <FlexColumnLayout sx={{ width: ['100%', '100%', '50%'] }}>
          <Text tag="h4" sx={{ marginBottom: 3, marginTop: ['5px', '5px', 0] }}>
            Ready to submit?
          </Text>
          <Text tag="body" color={theme.colors.ui3} hasPartialBolding>
            {`*Business Id*\n${businessId}\n\n*Application Id\n${applicationId}`}
          </Text>
        </FlexColumnLayout>
        <Image src={images.rocket} />
      </Box>
      <Button
        sx={{ marginTop: '20px', width: ['100%', '100%', FORM_BUTTON_WIDTH] }}
        type={ButtonTypeEnum.PRIMARY_BLUE}
        onClick={() => {
          if (fieldsAreValid) {
            submitApplication()
          } else {
            const appContainer = document.getElementById(APP_CONTAINER_ID)

            appContainer?.scrollTo({ top: 0, behavior: 'smooth' })
            dispatch(reduxActions.showErrors())
          }
        }}
        isLoading={isLoading}
      >
        Submit
      </Button>
    </FormPanel>
  )
}
