import { FlexRowLayout, Text } from '@northone/ui'
import React from 'react'
import { Image } from 'rebass'
import { images } from '../assets/images'

interface IAddButtonProps {
  text: string
  onClick: () => void
}
export const AddButton: React.FC<IAddButtonProps> = ({ onClick, text }) => (
  <FlexRowLayout onClick={onClick} sx={{ justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
    <FlexRowLayout sx={{ alignItems: 'center' }}>
      <Image sx={{ marginRight: 16 }} src={images.icons.add} />
      <Text tag="body-large-bold">{text}</Text>
    </FlexRowLayout>
    <Image src={images.icons.chevronRight} />
  </FlexRowLayout>
)
