import { createSelector } from 'reselect'
import { validate } from '../utils/validate'
import { IAddressState, IDocument, IOwner, IRootState, TDocumentType } from './state'
import { DateTime } from 'luxon'
import { ApplicationAmendment } from '../core/application-review/models/application'
import { BaseIDDocument, ManualReviewRequestBody } from '../core/application-review/models/manual-review'
import { documentTypes } from '../utils/constants'

const getNonEmptyFields = <T extends object>(obj: T): Partial<T> =>
  (Object.keys(obj) as Array<keyof T>).reduce((acc, curr) => (obj[curr] ? { ...acc, [curr]: obj[curr] } : acc), {})

export const addressHasUpdated = (address: IAddressState) => Object.keys(getNonEmptyFields(address)).length > 0

export const ownerHasUpdated = (owner: IOwner, documents: IDocument[]) => {
  const nonEmptyFields = getNonEmptyFields(owner)
  // eslint-disable-next-line
  const { id, address, isPrimaryContact, ...restOfFields } = nonEmptyFields
  return (
    Object.keys(restOfFields).length > 0 ||
    (address && addressHasUpdated(address)) ||
    documents.find(document => document.ownerId === owner.id)
  )
}

const getDocumentTypeLevel = (type: TDocumentType | null) => (!type ? null : documentTypes[type])

export const ownerDocumentTypesAreValid = (ownerDocuments: IDocument[]) => {
  const documentTypes = ownerDocuments.map(document => getDocumentTypeLevel(document.type))
  const hasOnePrimary = documentTypes.length === 1 && documentTypes[0] === 1
  const hasTwoSecondary = documentTypes.length === 2 && documentTypes.filter(type => type === 2).length === 2
  const hasOneSecondaryAndOneTertiary =
    documentTypes.length === 2 && documentTypes.includes(3) && documentTypes.includes(2)
  return !ownerDocuments.length || hasOnePrimary || hasTwoSecondary || hasOneSecondaryAndOneTertiary
}

const ownerIsValid = (
  { address, dateOfBirth, firstName, personId, lastName, phone, id }: IOwner,
  allDocuments: IDocument[],
) => {
  const ownerDocuments = allDocuments.filter(document => document.ownerId === id)
  const validationFields = {
    address: !addressHasUpdated(address) || validate.address(address),
    dateOfBirth: !dateOfBirth || validate.birthdate(dateOfBirth),
    firstName: !firstName || validate.firstName(firstName),
    lastName: !lastName || validate.lastName(lastName),
    personId: Boolean(personId),
    phone: !phone || validate.phoneNumber,
    documents: ownerDocumentTypesAreValid(ownerDocuments),
  }
  const invalidFields = (Object.keys(validationFields) as Array<keyof typeof validationFields>).filter(
    field => !validationFields[field],
  )
  return invalidFields.length === 0
}

const transformDocument = (document: IDocument, owners: IOwner[]): BaseIDDocument => ({
  address: formatAddress(document.address),
  dateIssued: formatDateInputToISODate(document.issueDate),
  ...(document.expiryDate && { dateOfExpiry: formatDateInputToISODate(document.expiryDate) }),
  idNumber: document.docId,
  issuedBy: document.issuedBy,
  personId: owners.find(owner => owner.id === document.ownerId)?.personId || '',
  referenceUrl: document.link,
  type: document.type || '',
})

export const getFieldsAreValid = createSelector(
  [(state: IRootState) => state],
  ({
    applicationStatus,
    businessAddress,
    businessDescription,
    businessName,
    businessPhone,
    dba,
    owners,
    documents,
    note,
  }): boolean => {
    const updatedOwners = owners.filter(owner => ownerHasUpdated(owner, documents))
    const validationFields = {
      note: Boolean(note.trim()),
      applicationStatus: Boolean(applicationStatus),
      businessAddress: !addressHasUpdated(businessAddress) || validate.address(businessAddress),
      businessName: !businessName || validate.businessName(businessName),
      businessDescription: !businessDescription || validate.description(businessDescription),
      businessPhone: !businessPhone || validate.phoneNumber(businessPhone),
      dba: !dba || validate.dba(dba),
      owners:
        updatedOwners.length === 0 ||
        updatedOwners.filter(owner => ownerIsValid(owner, documents)).length === updatedOwners.length,
      onlyOnePrimaryContact: updatedOwners.filter(owner => owner.isPrimaryContact).length <= 1,
      documents:
        !documents.length ||
        documents.filter(document => BaseIDDocument.check(transformDocument(document, owners))).length ===
          documents.length,
    }
    const invalidFields = (Object.keys(validationFields) as Array<keyof typeof validationFields>).filter(
      field => !validationFields[field],
    )

    return invalidFields.length === 0
  },
)

const formatAddress = (
  address: IAddressState,
): {
  line2?: string | undefined
  line1: string
  city: string
  state: string
  country: 'US'
  postalCode: string
} => ({
  line1: address.streetAddress,
  line2: address.suite || undefined,
  city: address.city,
  country: 'US',
  postalCode: address.zipCode,
  state: address.state,
})

export const formatDateInputToISODate = (date: string) => DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat('yyyy-MM-dd')

export const getManualReviewRequestBody = createSelector(
  [(state: IRootState) => state],
  ({
    applicationStatus,
    businessAddress,
    businessDescription,
    businessName,
    businessPhone,
    businessIndustry,
    dba,
    owners,
    note,
    documents,
  }): ManualReviewRequestBody | null => {
    if (!applicationStatus) {
      return null
    }

    const ownerAmendments: ApplicationAmendment['persons'] = owners
      .filter(owner => ownerHasUpdated(owner, documents))
      .map(({ address, dateOfBirth, firstName, lastName, phone, personId }) => ({
        id: personId,
        ...(addressHasUpdated(address) && { address: formatAddress(address) }),
        ...(dateOfBirth && { dob: formatDateInputToISODate(dateOfBirth) }),
        ...(phone && { phone }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
      }))

    const updatedPrimaryContact = owners.find(owner => owner.isPrimaryContact && ownerHasUpdated(owner, documents))
    const primaryContactAmendment: ApplicationAmendment['primaryContact'] | null = updatedPrimaryContact
      ? {
          ...(updatedPrimaryContact.phone && { cell: updatedPrimaryContact.phone }),
          ...(updatedPrimaryContact.phone && { phone: updatedPrimaryContact.phone }),
          ...(updatedPrimaryContact.dateOfBirth && {
            dob: formatDateInputToISODate(updatedPrimaryContact.dateOfBirth),
          }),
          ...(updatedPrimaryContact.firstName && { firstName: updatedPrimaryContact.firstName }),
          ...(updatedPrimaryContact.lastName && { lastName: updatedPrimaryContact.lastName }),
        }
      : null

    const businessAmendment: ApplicationAmendment['business'] = {
      ...(addressHasUpdated(businessAddress) && { address: formatAddress(businessAddress) }),
      ...(businessName && { legalName: businessName }),
      ...(dba && { dba }),
      ...(businessDescription && { description: businessDescription }),
      ...(businessPhone && { phone: businessPhone }),
      ...(businessIndustry && { naicsCode: businessIndustry.naicsCode.toString() }),
    }

    const businessHasUpdated = Object.keys(getNonEmptyFields(businessAmendment)).length > 0
    const ownersHaveUpdated = ownerAmendments.length
    return {
      outcome: applicationStatus,
      note,
      ...((ownersHaveUpdated || businessHasUpdated || primaryContactAmendment) && {
        applicationAmendment: {
          ...(businessHasUpdated && { business: businessAmendment }),
          ...(ownersHaveUpdated && { persons: ownerAmendments }),
          ...(primaryContactAmendment && {
            primaryContact: primaryContactAmendment,
          }),
        },
      }),
      ...(documents.length && { documents: documents.map(document => transformDocument(document, owners)) }),
    }
  },
)
