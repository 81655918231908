import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { getApplicationForReview, IGetApplicationForReviewResponse } from '../core/application-review'

export const useApplicationState = ({
  applicationId,
  businessId,
}: {
  applicationId: string | undefined
  businessId: string | undefined
}) => {
  const [data, setData] = useState<IGetApplicationForReviewResponse>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError>()

  useEffect(() => {
    const retrieveApplication = async () => {
      if (!applicationId || !businessId) return

      setLoading(true)

      try {
        setData(await getApplicationForReview({ applicationId, businessId }))
      } catch (error) {
        setError(error as AxiosError)
      }

      setLoading(false)
    }

    retrieveApplication()
  }, [applicationId, businessId])

  return {
    data,
    loading,
    error,
  }
}
