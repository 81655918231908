import React from 'react'
import { Box, BoxProps } from 'rebass'

interface IFlexLayoutProps extends BoxProps {
  boxRef?: React.RefObject<HTMLDivElement>
}

/**
 * Defines a flex container which shifts from Row to Column based on breakpoints
 */
export const ResponsiveColumnToRowLayout: React.FC<IFlexLayoutProps> = props => (
  <Box
    {...props}
    sx={{
      ...props.sx,
      display: 'flex',
      flexDirection: ['column', 'column', 'row'],
    }}
    ref={props.boxRef}
  >
    {props.children}
  </Box>
)
