import { icons } from './icons'
import { images as N1Images } from '@northone/ui'

export const images = {
  rocket: require('./rocket.svg'),
  wave: require('./wave.png'),
  airplanes: require('./airplane.png'),
  ...N1Images,
  icons: { ...icons, ...N1Images.icons },
}
