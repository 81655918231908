import validator from 'validator'
import * as z from 'zod'

import { isValidNaicsCode, isValidState, isPhoneNumber } from './utils'

export const Email = z.string().email().refine(validator.isEmail, 'Invalid Email')

export const NAICSCode = z.string().refine(isValidNaicsCode, 'Invalid NAICS code')

export const IPAddress = z.string().refine(validator.isIP, 'Invalid IP address')

export const USZipCode = z.string().regex(/^[0-9]{5}$/, 'Invalid US Zip Code')

export const USState = z.string().refine(isValidState, 'Invalid US State')

export const SocialSecurityNumber = z.string().regex(/^[0-9]{9}$/, 'Invalid SSN')

export const PhoneNumber = z.string().refine(isPhoneNumber, 'Invalid phone number')

export const ISO8601Date = z.string().refine(arg => {
  return validator.isISO8601(arg, { strict: true }) && arg.includes('-') // validator allows yyyymmdd and yyyy-mm-dd
}, 'Invalid date format, expecting yyyy-mm-dd')

export const ApplicationID = z.string()
export const PersonID = z.string()

export interface IBusinessApplication {
  businessId: string
  applicationId: string
}
