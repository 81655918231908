import * as z from 'zod'
import validator from 'validator'
import { DateTime } from 'luxon'

import * as ModelTypes from './types'

// Must be older than 18 and born after 1900-1-1
const DateOfBirth = ModelTypes.ISO8601Date.refine(dob => {
  return validator.isAfter(dob, '1900-1-1')
}, 'Invalid date').refine(dob => {
  return DateTime.fromFormat(dob, 'yyyy-MM-dd').diffNow('years').years <= -18
}, 'Must be older than 18')

const PrimaryContact = z.object({
  firstName: z.string().min(1).max(50),
  lastName: z.string().min(2).max(50),
  email: ModelTypes.Email.max(50),
  phone: ModelTypes.PhoneNumber,
  cell: ModelTypes.PhoneNumber,
  title: z.string().min(1).max(50),
  dob: DateOfBirth,
})

export const Address = z.object({
  line1: z.string().nonempty().max(30),
  line2: z.string().nonempty().max(30).optional(),
  city: z.string().nonempty().max(30),
  state: ModelTypes.USState,
  country: z.enum(['US']),
  postalCode: ModelTypes.USZipCode,
})

const Person = z.object({
  firstName: z.string().min(1).max(50),
  lastName: z.string().min(2).max(50),
  email: ModelTypes.Email.max(50),
  phone: ModelTypes.PhoneNumber,
  dob: DateOfBirth,
  address: Address,
  ssn: ModelTypes.SocialSecurityNumber,
  title: z.string().min(1).max(50),
  ownershipPercentage: z.number().min(0).max(100),
  type: z.enum(['ubo', 'control_person']),
})

const ApplicationPersons = z
  .array(Person)
  .refine(arg => {
    const controlPersons = arg.filter(p => p.type === 'control_person')
    return controlPersons.length === 1
  }, 'Applications must have exactly 1 control person')
  .refine(arg => {
    return arg.reduce((sum, p) => sum + p.ownershipPercentage, 0) <= 100
  }, 'Total ownership percentages can not exceed 100%')

const ApplicationMeta = z
  .object({
    ipAddress: ModelTypes.IPAddress,
    deviceModel: z.string().optional(),
    networkCarrier: z.string().optional(),
    networkCellular: z.boolean().optional(),
    onboardingFlow: z.enum(['mobile_onboarding', 'desktop_onboarding']).optional(),
    existingAccountHolder: z.boolean().optional(),
  })
  .nonstrict()

const Business = z.object({
  legalName: z.string().min(2).max(50),
  dba: z.string().nonempty().max(50).optional(),
  type: z.enum(['llc', 'corp', 'partnership', 'soleprop']),
  naicsCode: ModelTypes.NAICSCode,
  description: z.string().nonempty(),
  ein: z
    .string()
    .regex(/^[0-9]{9}$/, 'Invalid EIN')
    .optional(), // not required for soleprops
  website: z.string().max(50).refine(validator.isURL, 'Invalid website URL').optional(),
  phone: ModelTypes.PhoneNumber,
  address: Address,
  numberOfEmployees: z.number().max(999999999999999),
  annualRevenue: z.number().max(999999999999999),
  numberOfYearsAtLocation: z.number().max(999),
  typeOfProductsOrServices: z.string(),
})

const ApplicationBusiness = Business.refine(arg => {
  // businesses must have an ein if they are not a sole prop
  return Boolean(arg.ein) || arg.type === 'soleprop'
}, 'EIN required for Business').refine(arg => {
  // businesses must not have an ein if they are a sole prop
  return !(arg.type === 'soleprop' && Boolean(arg.ein))
}, 'EIN is not required for sole proprietorships')

export const BaseApplication = z.object({
  userId: z.string().uuid(),
  primaryContact: PrimaryContact,
  business: ApplicationBusiness,
  persons: ApplicationPersons,
  meta: ApplicationMeta,
})

export type BaseApplication = z.infer<typeof BaseApplication>

export const Application = BaseApplication.extend({
  id: z.string(),
  business: Business.extend({
    id: z.string().uuid(),
    naicsDescription: z.string(),
  }),
  persons: z.array(
    Person.extend({
      id: z.string(),
    }),
  ),
})

export type Application = z.infer<typeof Application>

export const ApplicationAmendment = z.object({
  business: Business.partial().optional(),
  primaryContact: PrimaryContact.partial().optional(),
  persons: z.array(Person.omit({ ssn: true }).partial().extend({ id: z.string() })).optional(),
})

export type ApplicationAmendment = z.infer<typeof ApplicationAmendment>
