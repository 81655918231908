import { InfoBlockModal, LongTextInput, Text, theme } from '@northone/ui'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'rebass'
import { SelectorBox } from '../../components/selector-box'
import { FormContainer, FormPanel } from '../../layouts/form'
import { ResponsiveColumnToRowLayout } from '../../layouts/responsive-column-to-row'
import { reduxActions } from '../../redux/actions'
import { IOwner, IRootState, TApplicationStatus } from '../../redux/state'
import { BusinessFields } from './business-fields'
import { OwnerFields } from './owner-fields'
import { SubmitBlock } from './submit-block'
import { SuccessScreen } from './success-screen'
import { DuplicateSSNBlock } from './duplicate-ssn-block'
import { getApplicationPathParameters } from '../../utils/helpers'
import { PageHeading } from '../../components/PageHeading'

export const FormPage: React.FC = () => {
  const note = useSelector<IRootState, string>(state => state.note)
  const status = useSelector<IRootState, TApplicationStatus>(state => state.applicationStatus)
  const dispatch = useDispatch()
  const owners = useSelector<IRootState, IOwner[]>(state => state.owners)
  const isShowingErrors = useSelector<IRootState, boolean>(state => state.isShowingErrors)
  const { businessId, applicationId } = getApplicationPathParameters()
  const submitCompleted = useSelector<IRootState, boolean>(state => state.submitCompleted)

  if (submitCompleted) {
    return <SuccessScreen />
  }

  return (
    <FormContainer>
      <InfoBlockModal
        closeModal={() => null}
        buttons={[]}
        title="Oh no!"
        subtitle="Make sure url is in the format /businesses/<businessId>/applications/<applicationId> or /applications/reject"
        isOpen={!businessId || !applicationId}
        modalSx={{ width: 450 }}
      />
      <PageHeading>Application Manual Review</PageHeading>

      <FormPanel>
        {isShowingErrors && status === null && (
          <Text tag={'label-bold'} textColor={theme.colors.error100}>
            Must pick one
          </Text>
        )}
        <ResponsiveColumnToRowLayout>
          <SelectorBox
            sx={{ flex: 1 }}
            title="Approve"
            isSelected={status === 'Approve'}
            onSelect={() => dispatch(reduxActions.setApplicationStatus('Approve'))}
          />
          <SelectorBox
            sx={{ flex: 1, marginRight: 0, marginBottom: 10 }}
            title="Reject"
            isSelected={status === 'Reject'}
            onSelect={() => dispatch(reduxActions.setApplicationStatus('Reject'))}
          />
        </ResponsiveColumnToRowLayout>
        <LongTextInput
          autoFocus={false}
          value={note}
          onChange={v => dispatch(reduxActions.setNote(v))}
          label="Note"
          placeholder="Note"
          errorMessage={isShowingErrors && !note ? 'Required' : ''}
        />
      </FormPanel>
      <DuplicateSSNBlock />
      <BusinessFields />
      {owners.map(owner => (
        <Box key={owner.id}>
          <OwnerFields id={owner.id} />
        </Box>
      ))}
      <SubmitBlock />
    </FormContainer>
  )
}
