import { ReduxActionEnum, TReduxActions } from './actions'
import { getRandomId, initialDocument, initialOwner, initialRootState, IRootState } from './state'

export const reducer = (state: IRootState = initialRootState, action: TReduxActions): IRootState => {
  switch (action.type) {
    case ReduxActionEnum.SET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload,
      }
    case ReduxActionEnum.SET_DBA:
      return {
        ...state,
        dba: action.payload,
      }
    case ReduxActionEnum.SET_BUSINESS_ADDRESS:
      return {
        ...state,
        businessAddress: action.payload,
      }
    case ReduxActionEnum.SET_BUSINESS_PHONE:
      return {
        ...state,
        businessPhone: action.payload,
      }
    case ReduxActionEnum.SET_BUSINESS_INDUSTRY:
      return {
        ...state,
        businessIndustry: action.payload,
      }
    case ReduxActionEnum.SET_NOTE:
      return {
        ...state,
        note: action.payload,
      }
    case ReduxActionEnum.SET_APPLICATION_STATUS:
      return {
        ...state,
        applicationStatus: action.payload,
      }
    case ReduxActionEnum.SET_DESCRIPTION:
      return {
        ...state,
        businessDescription: action.payload,
      }
    case ReduxActionEnum.ADD_OWNER:
      return {
        ...state,
        owners: [...state.owners, { ...initialOwner, id: getRandomId() }],
      }
    case ReduxActionEnum.DELETE_OWNER:
      return {
        ...state,
        owners: state.owners.filter(owner => owner.id !== action.payload),
        documents: state.documents.filter(document => document.ownerId !== action.payload),
      }
    case ReduxActionEnum.UPDATE_OWNER:
      return {
        ...state,
        owners: state.owners.map(owner =>
          owner.id === action.payload.id ? { ...owner, ...action.payload.fields } : owner,
        ),
      }
    case ReduxActionEnum.SHOW_ERRORS:
      return {
        ...state,
        isShowingErrors: true,
      }
    case ReduxActionEnum.FINISH_SUBMIT:
      return {
        ...state,
        submitCompleted: true,
      }
    case ReduxActionEnum.ADD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, { ...initialDocument, ownerId: action.payload, id: getRandomId() }],
      }
    case ReduxActionEnum.DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(document => document.id !== action.payload),
      }
    case ReduxActionEnum.UPDATE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.map(document =>
          document.id === action.payload.documentId ? { ...document, ...action.payload.fields } : document,
        ),
      }
    default:
      return state
  }
}
