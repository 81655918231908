export const getApplicationPathParameters = (): {
  businessId: string | undefined
  applicationId: string | undefined
} => {
  const pathParams = window.location.pathname.split('/').filter(v => v)
  const businessId = pathParams[pathParams.indexOf('businesses') + 1]
  const applicationId = pathParams[pathParams.indexOf('applications') + 1]

  return {
    businessId,
    applicationId,
  }
}
