import { GraphQLClient, gql } from 'graphql-request'

import { config } from '../../utils/environment'

export interface IKYBIndustry {
  vertical: string
  subCategory: string
  naicsCategory: string
  naicsCode: number
  keywords: string
}

export interface IGroupedKYCIndustry {
  vertical: string
  industries: IKYBIndustry[]
}

const endpoint = config.gqlURI
const graphQLClient = new GraphQLClient(endpoint)

const NAICS_QUERY = gql`
  query naics {
    naics {
      groupedIndustries {
        vertical
        industries {
          naicsCategory
          naicsCode
          subCategory
          keywords
          vertical
        }
      }
      defaultIndustry {
        naicsCategory
        naicsCode
        subCategory
        keywords
        vertical
      }
    }
  }
`
interface NaicsQuery {
  naics: {
    groupedIndustries: Array<{
      vertical: string
      industries: Array<{
        naicsCategory: string
        naicsCode: number
        subCategory: string
        keywords: string
        vertical: string
      }>
    }>
    defaultIndustry: {
      naicsCategory: string
      naicsCode: number
      subCategory: string
      keywords: string
      vertical: string
    }
  }
}

export const getIndustries = async (): Promise<{
  industries: IGroupedKYCIndustry[]
  defaultIndustry: IKYBIndustry
}> => {
  const data = await graphQLClient.request<NaicsQuery>(NAICS_QUERY)
  return { defaultIndustry: data.naics.defaultIndustry, industries: data.naics.groupedIndustries }
}
