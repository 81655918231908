import { Field, FlexColumnLayout, FlexRowLayout, Text, theme, useClickOutside, WithDropdownMenu } from '@northone/ui'
import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { Box, Image } from 'rebass'
import { images } from '../../assets/images'
import { IRootState, TDocumentType } from '../../redux/state'
import { documentTypes } from '../../utils/constants'
import { useSelector } from 'react-redux'

const ItemBox: React.FC<{ onClick: () => void }> = ({ children, onClick }) => (
  <FlexRowLayout
    sx={{
      height: 40,
      paddingX: 16,
      backgroundColor: theme.colors.ui7,
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      ':hover': { backgroundColor: theme.colors.gold10 },
    }}
    onClick={onClick}
  >
    {children}
  </FlexRowLayout>
)

interface IDocumentTypeSectionProps {
  isExpanded: boolean
  onSectionClick: () => void
  section: number
  setType: (type: TDocumentType) => void
  selectedType: TDocumentType | null
}

const documentTypeSections: { [key: number]: string } = {
  1: 'Primary',
  2: 'Secondary',
  3: 'Tertiary',
}

const DocumentTypeSection: React.FC<IDocumentTypeSectionProps> = ({
  isExpanded,
  onSectionClick,
  section,
  setType,
  selectedType,
}) => {
  const types = (Object.keys(documentTypes) as TDocumentType[]).filter(type => documentTypes[type] === section)
  return (
    <Box>
      <ItemBox onClick={onSectionClick}>
        <Text tag="body">{documentTypeSections[section]}</Text>
        <Image src={isExpanded ? images.icons.chevronUp : images.icons.chevronDown} />
      </ItemBox>
      <motion.div
        style={{ overflow: 'hidden' }}
        initial={{ height: 0 }}
        transition={{ duration: 0.2 }}
        animate={{ height: isExpanded ? 'auto' : 0 }}
      >
        {types.map((type, index) => (
          <ItemBox key={index} onClick={() => setType(type)}>
            <Text sx={{ paddingLeft: 10 }} tag={selectedType === type ? 'body-small-bold' : 'body-small'}>
              {type}
            </Text>
          </ItemBox>
        ))}
      </motion.div>
    </Box>
  )
}

interface IDocumentTypeDropdownProps {
  selectedType: TDocumentType | null
  setType: (type: TDocumentType) => void
}

export const DocumentTypeDropdown: React.FC<IDocumentTypeDropdownProps> = ({ selectedType, setType }) => {
  const [menuIsVisible, setMenuIsVisible] = useState(false)
  const [expandedSection, setExpandedSection] = useState<number | null>(null)
  const isShowingErrors = useSelector<IRootState, boolean>(state => state.isShowingErrors)

  const ref = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  useClickOutside([ref, menuRef], () => setMenuIsVisible(false))

  return (
    <WithDropdownMenu
      content={
        <FlexColumnLayout sx={{ paddingY: '12px', maxHeight: 300, overflowY: 'scroll' }} boxRef={menuRef}>
          {Object.keys(documentTypeSections).map(sectionStr => {
            const section = Number(sectionStr)
            const isSelected = section === expandedSection
            return (
              <DocumentTypeSection
                key={section}
                section={section}
                isExpanded={isSelected}
                onSectionClick={() => (isSelected ? setExpandedSection(null) : setExpandedSection(section))}
                setType={type => {
                  setMenuIsVisible(false)
                  setType(type)
                }}
                selectedType={selectedType}
              />
            )
          })}
        </FlexColumnLayout>
      }
      visible={menuIsVisible}
    >
      <Field
        subTextContainerStyling={{ height: 0 }}
        sx={{ padding: '10px 8px 10px 12px', cursor: 'pointer' }}
        isFocused={menuIsVisible}
        label={'Type'}
        backgroundColor={theme.colors.ui7}
        errorMessage={isShowingErrors && !selectedType ? 'Required' : ''}
      >
        <Box ref={ref} sx={{ display: 'flex' }} onClick={() => setMenuIsVisible(!menuIsVisible)}>
          <Text
            tabIndex={0}
            tag="body"
            sx={{
              width: '100%',
              height: '100%',
              outline: 'none',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            textColor={selectedType ? theme.colors.ui1 : theme.colors.ui2}
          >
            {selectedType || 'Type'}
          </Text>
          <Box sx={{ height: 20 }}>
            <Image src={menuIsVisible ? images.icons.chevronUp : images.icons.chevronDown} />
          </Box>
        </Box>
      </Field>
    </WithDropdownMenu>
  )
}
