import { IKYBIndustry } from '../core/application-review/industries'
import { IAddressState, IDocument, IOwner, TApplicationStatus } from './state'
import { createAction, TActionsUnion } from './utils'

export enum ReduxActionEnum {
  SET_BUSINESS_NAME = 'SET_BUSINESS_NAME',
  SET_DBA = 'SET_DBA',
  SET_BUSINESS_ADDRESS = 'SET_BUSINESS_ADDRESS',
  SET_NOTE = 'SET_NOTE',
  SET_BUSINESS_PHONE = 'SET_BUSINESS_PHONE',
  SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  SET_BUSINESS_INDUSTRY = 'SET_BUSINESS_INDUSTRY',
  ADD_OWNER = 'ADD_OWNER',
  DELETE_OWNER = 'DELETE_OWNER',
  UPDATE_OWNER = 'UPDATE_OWNER',
  SHOW_ERRORS = 'SHOW_ERRORS',
  FINISH_SUBMIT = 'FINISH_SUBMIT',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  UPDATE_DOCUMENT = 'UPDATE_DOCUMENT',
}

export const reduxActions = {
  setBusinessName: (businessName: string) => createAction(ReduxActionEnum.SET_BUSINESS_NAME, businessName),
  setDba: (dba: string) => createAction(ReduxActionEnum.SET_DBA, dba),
  setBusinessAddress: (address: IAddressState) => createAction(ReduxActionEnum.SET_BUSINESS_ADDRESS, address),
  setNote: (phone: string) => createAction(ReduxActionEnum.SET_NOTE, phone),
  setApplicationStatus: (status: TApplicationStatus) => createAction(ReduxActionEnum.SET_APPLICATION_STATUS, status),
  setBusinessPhone: (phone: string) => createAction(ReduxActionEnum.SET_BUSINESS_PHONE, phone),
  setDescription: (description: string) => createAction(ReduxActionEnum.SET_DESCRIPTION, description),
  setBusinessIndustry: (industry: IKYBIndustry) => createAction(ReduxActionEnum.SET_BUSINESS_INDUSTRY, industry),
  addOwner: () => createAction(ReduxActionEnum.ADD_OWNER),
  deleteOwner: (id: string) => createAction(ReduxActionEnum.DELETE_OWNER, id),
  updateOwner: (id: string, fields: Partial<IOwner>) => createAction(ReduxActionEnum.UPDATE_OWNER, { id, fields }),
  showErrors: () => createAction(ReduxActionEnum.SHOW_ERRORS),
  finishSubmit: () => createAction(ReduxActionEnum.FINISH_SUBMIT),
  addDocument: (ownerId: string) => createAction(ReduxActionEnum.ADD_DOCUMENT, ownerId),
  deleteDocument: (documentId: string) => createAction(ReduxActionEnum.DELETE_DOCUMENT, documentId),
  updateDocument: (documentId: string, fields: Partial<IDocument>) =>
    createAction(ReduxActionEnum.UPDATE_DOCUMENT, { documentId, fields }),
}

export type TReduxActions = TActionsUnion<typeof reduxActions>
