import React, { useRef, useState } from 'react'
import {
  WithDropdownMenu,
  FlexColumnLayout,
  Field,
  Text,
  theme,
  FlexRowLayout,
  useClickOutside,
  images,
} from '@northone/ui'
import { Box, Image } from 'rebass'

import { motion } from 'framer-motion'
import { IGroupedKYCIndustry, IKYBIndustry } from '../core/application-review/industries'

const IndustryItemBox: React.FC<{ onClick: () => void }> = ({ children, onClick }) => (
  <FlexRowLayout
    sx={{
      height: 40,
      paddingX: 16,
      backgroundColor: theme.colors.ui7,
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      ':hover': { backgroundColor: theme.colors.gold10 },
    }}
    onClick={onClick}
  >
    {children}
  </FlexRowLayout>
)

interface IIndustryItemProps {
  onClick: () => void
  isSelected: boolean
  text: string
}

const VerticalItem: React.FC<IIndustryItemProps> = ({ text, onClick, isSelected }) => (
  <IndustryItemBox onClick={onClick}>
    <Text tag="body">{text}</Text>
    <Image src={isSelected ? images.icons.chevronUp : images.icons.chevronDown} />
  </IndustryItemBox>
)

const SubCategoryItem: React.FC<IIndustryItemProps> = ({ text, onClick, isSelected }) => (
  <IndustryItemBox onClick={onClick}>
    <Text sx={{ paddingLeft: 24 }} tag={isSelected ? 'body-bold' : 'body'}>
      {text}
    </Text>
  </IndustryItemBox>
)

interface IIndustryCategoryGroupProps {
  isExpanded: boolean
  vertical: string
  subCategories: IIndustryItemProps[]
  onClick: () => void
}

const IndustryCategoryGroup: React.FC<IIndustryCategoryGroupProps> = ({
  vertical,
  subCategories,
  isExpanded,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <Box ref={ref}>
      <VerticalItem isSelected={isExpanded} onClick={onClick} text={vertical} />
      <motion.div
        style={{ overflow: 'hidden' }}
        initial={{ height: 0 }}
        transition={{ duration: 0.2 }}
        onAnimationComplete={() => {
          if (isExpanded) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }}
        animate={{ height: isExpanded ? 'auto' : 0 }}
      >
        {subCategories.map((props, index) => (
          <SubCategoryItem key={index} {...props} />
        ))}
      </motion.div>
    </Box>
  )
}

interface IIndustryDropdownProps {
  groupedIndustries: IGroupedKYCIndustry[]
  defaultIndustry: IKYBIndustry | null
  selectedIndustry: IKYBIndustry | null
  setIndustry: (industry: IKYBIndustry) => void
}

export const IndustryDropdown: React.FC<IIndustryDropdownProps> = ({
  selectedIndustry,
  setIndustry,
  groupedIndustries,
  defaultIndustry,
}) => {
  const [menuIsVisible, setMenuIsVisible] = useState(false)
  const [expandedVertical, setExpandedVertical] = useState(selectedIndustry ? selectedIndustry.vertical : '')
  const ref = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  useClickOutside([ref, menuRef], () => setMenuIsVisible(false))

  return (
    <WithDropdownMenu
      content={
        <FlexColumnLayout boxRef={menuRef} sx={{ paddingY: '12px', maxHeight: 300, overflowY: 'scroll' }}>
          {groupedIndustries.map((industryGroup, index) => (
            <IndustryCategoryGroup
              key={index}
              isExpanded={expandedVertical === industryGroup.vertical}
              onClick={() => {
                if (expandedVertical !== industryGroup.vertical) {
                  setExpandedVertical(industryGroup.vertical)
                } else {
                  setExpandedVertical('')
                }
              }}
              vertical={industryGroup.vertical}
              subCategories={industryGroup.industries.map(industry => ({
                text: industry.subCategory,
                onClick: () => {
                  setMenuIsVisible(false)
                  setIndustry(industry)
                },
                isSelected: subCategoryIsSelected(selectedIndustry, industry),
              }))}
            />
          ))}
          <Box>
            <IndustryItemBox
              onClick={() => {
                setMenuIsVisible(false)
                if (!defaultIndustry) {
                  throw new Error('Missing default industry')
                }
                setIndustry(defaultIndustry)
              }}
            >
              <Text
                tag={
                  selectedIndustry && selectedIndustry.subCategory === defaultIndustry?.subCategory
                    ? 'body-bold'
                    : 'body'
                }
              >
                {copy.noIndustry}
              </Text>
            </IndustryItemBox>
          </Box>
        </FlexColumnLayout>
      }
      visible={menuIsVisible}
    >
      <Field
        sx={{ padding: '10px 8px 10px 12px', cursor: 'pointer' }}
        isFocused={menuIsVisible}
        label={copy.label}
        subTextContainerStyling={{ height: 0 }}
      >
        <Box ref={ref} sx={{ display: 'flex' }} onClick={() => setMenuIsVisible(!menuIsVisible)}>
          <Text
            tabIndex={0}
            tag="body"
            sx={{
              width: '100%',
              height: '100%',
              outline: 'none',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            textColor={selectedIndustry ? theme.colors.ui1 : theme.colors.ui2}
          >
            {selectedIndustry?.subCategory || copy.placeholder}
          </Text>
          <Box sx={{ height: 20 }}>
            <Image src={menuIsVisible ? images.icons.chevronUp : images.icons.chevronDown} />
          </Box>
        </Box>
      </Field>
    </WithDropdownMenu>
  )
}

export const subCategoryIsSelected = (selectedIndustry: IKYBIndustry | null, industry: IKYBIndustry) =>
  Boolean(
    selectedIndustry &&
      selectedIndustry.subCategory === industry.subCategory &&
      selectedIndustry.vertical === industry.vertical,
  )

const copy = {
  title: 'What industry is your business in?',
  label: 'Industry',
  placeholder: 'Select industry',
  noIndustry: 'My industry is not listed',
}
