import env, { IOptionalVariable } from 'env-var'
import pkg from '../../package.json'

export const RELEASE = pkg ? `${pkg.name}@${pkg.version}` : null

const stage = env.get('REACT_APP_ENVIRONMENT').default('dev').required().asEnum(['dev', 'prod', 'staging'])

console.log('stage', stage)

export const isProduction = () => stage === 'prod'

/**
 * Wrapper function for env-var to inject default
 * values only for development environments
 *
 * @param variableName Variable name to get from process.env
 * @param defaultValue Value to use as default during development
 * @returns The resulting env-var chain
 */
const envWithDevDefault = (variableName: string, defaultValue: string): IOptionalVariable => {
  const value = env.get(variableName)
  return isProduction() ? value : value.default(defaultValue)
}

export const config = {
  stage,
  env: env.get('NODE_ENV').default('development').required().asEnum(['production', 'development', 'test']),
  awsRegion: envWithDevDefault('REACT_APP_AWS_REGION', 'us-east-2').required().asString(),
  cognitoUserPoolId: envWithDevDefault('REACT_APP_COGNITO_USER_POOL_ID', 'us-east-2_8P7tEaMOY').required().asString(),
  cognitoClientId: envWithDevDefault('REACT_APP_COGNITO_CLIENT_ID', '42rr6i3v6p3knic60cc7l00eu1').required().asString(),
  applicationReviewUrl: envWithDevDefault(
    'REACT_APP_APPLICATION_REVIEW_URL',
    'https://distill.dev.internal-service.northoneapps.com/dev',
  )
    .required()
    .asString(),
  gqlURI: envWithDevDefault('REACT_APP_GQL_URI', 'https://dobby-graphql.dev.aws.northoneapps.com')
    .required()
    .asString(),
}
