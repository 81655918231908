import { config } from '../utils/environment'

const REGION = config.awsRegion
const USER_POOL_ID = config.cognitoUserPoolId
const CLIENT_ID = config.cognitoClientId

export const AWSConfig = {
  region: REGION,
  userPoolId: USER_POOL_ID,
  userPoolWebClientId: CLIENT_ID,
}
