import React from 'react'
import { Box, SxStyleProp } from 'rebass'

export const NorthOneCenteredLogo: React.FC<{ sx?: SxStyleProp }> = ({ sx }) => (
  <Box sx={{ marginY: [22, 40], ...sx }}>
    <svg width="100%" height="30" viewBox="0 0 180 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.52423 15.6763L2.45882 22.8948C1.71027 24.2163 1.88992 25.8144 2.90794 26.9208C3.56666 27.6276 4.40503 27.9964 5.27335 27.9964C5.78236 27.9964 6.32131 27.8735 6.80038 27.6276L15.0832 23.29L10.2075 20.7367C8.47671 19.8278 7.2494 18.2649 6.70221 16.4195C6.62924 16.1734 6.56999 15.9254 6.52423 15.6763Z"
        fill="#A59878"
      />
      <path
        d="M17.0448 2C18.7216 2 20.2486 2.89125 21.087 4.39716L31.5067 22.8983C32.2553 24.2198 32.0756 25.818 31.0576 26.9243C30.3989 27.6312 29.5605 28 28.6922 28C28.1832 28 27.6442 27.8771 27.1652 27.6312L11.0265 19.1797C9.73904 18.5035 8.81084 17.3357 8.39165 15.922C7.97247 14.5083 8.15212 13.0024 8.87072 11.7116L12.9728 4.39716C13.8411 2.89125 15.3382 2 17.0448 2Z"
        fill="#7E6C3E"
      />
      <path
        d="M59.65 6.28415V18.116L48.214 4.56875C48.0821 4.4148 47.8842 4.37082 47.7082 4.43679L45.0252 5.53641C44.8492 5.60239 44.7393 5.75633 44.7393 5.95426V25.4614C44.7393 25.7034 44.9372 25.9013 45.1791 25.9013H48.5439C48.7858 25.9013 48.9838 25.7034 48.9838 25.4614V12.0461L60.5297 25.7473C60.6177 25.8573 60.7277 25.9013 60.8596 25.9013H63.4547C63.6966 25.9013 63.8946 25.7034 63.8946 25.4614V4.96461C63.8946 4.65672 63.5867 4.43679 63.3008 4.54676L59.9359 5.86629C59.76 5.95426 59.65 6.10821 59.65 6.28415Z"
        fill="#242424"
      />
      <path
        d="M73.7607 10.0405C71.4735 10.0405 69.5602 10.8323 67.9767 12.3937C66.3933 13.9771 65.6235 15.8905 65.6235 18.1777C65.6235 20.4649 66.4153 22.3782 67.9767 23.9617C69.5602 25.5451 71.4735 26.3148 73.7607 26.3148C76.0479 26.3148 77.9832 25.5231 79.5667 23.9617C81.1501 22.3782 81.9418 20.4649 81.9418 18.1777C81.9418 15.8905 81.1501 13.9771 79.5667 12.3937C77.9612 10.8323 76.0479 10.0405 73.7607 10.0405ZM76.7516 21.2346C75.9599 22.0263 74.9483 22.4442 73.7607 22.4442C72.5731 22.4442 71.5835 22.0483 70.7917 21.2346C70 20.4429 69.6041 19.4093 69.6041 18.1777C69.6041 16.9461 70 15.9345 70.7917 15.1208C71.5835 14.329 72.5731 13.9112 73.7607 13.9112C74.9483 13.9112 75.9599 14.307 76.7516 15.1208C77.5434 15.9125 77.9612 16.9461 77.9612 18.1777C77.9612 19.4093 77.5434 20.4209 76.7516 21.2346Z"
        fill="#242424"
      />
      <path
        d="M84.1103 25.8938H87.2112C87.4531 25.8938 87.651 25.6958 87.651 25.4539V18.6104C87.651 17.1369 88.1348 15.8692 89.0805 15.2314C89.9162 14.6816 90.8399 14.4177 91.8735 14.4617C92.1155 14.4617 92.3354 14.2638 92.3354 14.0218V10.6091C92.3354 10.3452 92.1155 10.1473 91.8735 10.1693C91.0158 10.2352 90.2241 10.4772 89.4984 10.895C88.6407 11.3788 88.0249 12.1266 87.673 13.1162V11.1369C87.673 10.829 87.3651 10.6091 87.0792 10.7191L83.9563 11.9506C83.7804 12.0166 83.6704 12.1706 83.6704 12.3685V25.4539C83.6704 25.6958 83.8683 25.8938 84.1103 25.8938Z"
        fill="#242424"
      />
      <path
        d="M94.0649 10.4165H95.4064H95.4284V7.72796C95.4284 7.53002 95.5383 7.37608 95.7143 7.3101L98.8152 6.07853C99.1011 5.96857 99.409 6.18849 99.409 6.49639V10.4165H103.467C103.709 10.4165 103.907 10.6144 103.907 10.8563L103.907 13.8669C103.907 14.1089 103.709 14.3068 103.467 14.3068H99.409L99.409 19.9435C99.409 21.5342 100.42 22.2765 101.587 22.2765C102.151 22.2765 102.756 22.1603 103.225 22.0215C103.541 21.9279 103.92 22.1657 103.92 22.4955V25.588C103.92 25.7649 103.811 25.924 103.642 25.9772C103.212 26.1127 102.541 26.3062 101.587 26.3062C99.0419 26.3062 95.4284 24.5034 95.4284 20.2617V18.9891L95.4284 14.3068H94.0648C93.8229 14.3068 93.625 14.1089 93.625 13.8669L93.625 10.8563C93.625 10.6144 93.8229 10.4165 94.0649 10.4165Z"
        fill="#242424"
      />
      <path
        d="M132.733 3.86108C129.61 3.86108 126.971 4.93871 124.794 7.11595C122.617 9.29318 121.539 11.9323 121.539 15.0772C121.539 18.2221 122.617 20.8831 124.794 23.0384C126.971 25.2156 129.61 26.2932 132.733 26.2932C135.856 26.2932 138.517 25.2156 140.694 23.0384C142.872 20.8611 143.971 18.2221 143.971 15.0772C143.971 11.9323 142.872 9.27119 140.694 7.11595C138.517 4.9607 135.856 3.86108 132.733 3.86108ZM137.703 20.1574C136.362 21.4769 134.712 22.1367 132.733 22.1367C130.754 22.1367 129.104 21.4769 127.763 20.1574C126.421 18.8378 125.762 17.1444 125.762 15.0772C125.762 13.0319 126.421 11.3165 127.763 9.99694C129.104 8.65541 130.754 7.99564 132.733 7.99564C134.712 7.99564 136.362 8.65541 137.703 9.99694C139.045 11.3385 139.705 13.0319 139.705 15.0772C139.705 17.1444 139.045 18.8378 137.703 20.1574Z"
        fill="#242424"
      />
      <path
        d="M154.245 10.0405C152.156 10.0405 150.617 10.7663 149.649 12.1958V11.1401C149.649 10.8323 149.341 10.6123 149.055 10.7223L145.954 11.9319C145.778 11.9978 145.668 12.1518 145.668 12.3497V25.4351C145.668 25.6771 145.866 25.875 146.108 25.875H149.209C149.451 25.875 149.649 25.6771 149.649 25.4351V17.5619C149.649 16.2863 149.957 15.3407 150.573 14.7029C151.189 14.0651 152.002 13.7572 153.036 13.7572C153.96 13.7572 154.707 14.0211 155.257 14.5709C155.807 15.1208 156.093 15.9125 156.093 16.9241V25.4571C156.093 25.6991 156.291 25.897 156.533 25.897H159.634C159.875 25.897 160.073 25.6991 160.073 25.4571V16.4403C160.073 14.439 159.524 12.8775 158.424 11.7559C157.324 10.6123 155.939 10.0405 154.245 10.0405Z"
        fill="#242424"
      />
      <path
        d="M177.691 18.1739C177.691 15.8647 176.966 13.9294 175.492 12.3679C174.019 10.8065 172.149 10.0148 169.906 10.0148C167.531 10.0148 165.574 10.7845 164.056 12.346C162.539 13.9074 161.769 15.8427 161.769 18.1519C161.769 20.5051 162.539 22.4404 164.078 23.9799C165.618 25.5193 167.663 26.2891 170.214 26.2891C172.985 26.2891 175.118 25.3214 176.636 23.3861C176.79 23.1662 176.746 22.8583 176.504 22.7263L174.041 21.2968C173.865 21.1869 173.645 21.2308 173.491 21.3848C172.721 22.2425 171.644 22.6603 170.258 22.6603C167.883 22.6603 166.453 21.6927 165.904 19.7574H177.164C177.384 19.7574 177.56 19.5814 177.604 19.3835C177.647 18.9876 177.691 18.5698 177.691 18.1739ZM165.838 16.7224C166.058 15.7328 166.519 14.963 167.223 14.4352C167.927 13.8854 168.807 13.6215 169.884 13.6215C170.786 13.6215 171.6 13.8854 172.303 14.3912C173.007 14.8971 173.491 15.6888 173.711 16.7444H165.838V16.7224Z"
        fill="#242424"
      />
      <path
        d="M105.65 3.67815V25.4568C105.65 25.6987 105.848 25.8967 106.09 25.8967H109.191C109.433 25.8967 109.631 25.6987 109.631 25.4568V17.5616C109.631 16.286 109.938 15.3403 110.554 14.7026C111.17 14.0648 111.984 13.7569 113.017 13.7569C113.941 13.7569 114.689 14.0208 115.239 14.5706C115.788 15.1204 116.074 15.9121 116.074 16.9238V25.4568C116.074 25.6987 116.272 25.8967 116.514 25.8967H119.615C119.857 25.8967 120.055 25.6987 120.055 25.4568V16.44C120.055 14.4387 119.505 12.8772 118.405 11.7556C117.306 10.634 115.92 10.0622 114.227 10.0622C112.138 10.0622 110.598 10.7879 109.631 12.2174V2.44658C109.631 2.13869 109.323 1.91877 109.037 2.02873L105.936 3.2603C105.76 3.32627 105.65 3.48022 105.65 3.67815Z"
        fill="#242424"
      />
    </svg>
  </Box>
)
