import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IndustryDropdown } from '../../components/industry-dropdown'
import { getIndustries, IGroupedKYCIndustry, IKYBIndustry } from '../../core/application-review/industries'

import { reduxActions } from '../../redux/actions'
import { IRootState } from '../../redux/state'

export const IndustrySelect: React.FC = () => {
  useEffect(() => {
    const retrieveIndustries = async () => {
      const response = await getIndustries()
      setIndustries(response.industries)
      setDefaultIndustries(response.defaultIndustry)
    }
    retrieveIndustries()
  }, [])

  const selectedIndustry = useSelector<IRootState, IKYBIndustry | null>(state => state.businessIndustry)
  const dispatch = useDispatch()
  const [industries, setIndustries] = useState<IGroupedKYCIndustry[]>([])
  const [defaultIndustry, setDefaultIndustries] = useState<IKYBIndustry | null>(null)

  return (
    <IndustryDropdown
      groupedIndustries={industries}
      defaultIndustry={defaultIndustry}
      setIndustry={industry => dispatch(reduxActions.setBusinessIndustry(industry))}
      selectedIndustry={selectedIndustry}
    />
  )
}
