import { motion, useAnimation } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { Box } from 'rebass'
import { FormPanel, PANEL_PADDING } from './form'

interface IExpandingFormProps {
  viewA: React.ReactElement
  viewB: React.ReactElement
  activeView: 'viewA' | 'viewB'
}

export const ExpandingForm: React.FC<IExpandingFormProps> = ({ viewA, viewB, activeView }) => {
  const containerControls = useAnimation()
  const viewAControls = useAnimation()
  const viewBControls = useAnimation()

  const viewARef = useRef<HTMLDivElement>(null)
  const viewBRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    const animate = async () => {
      containerControls.set({ overflow: 'visible' })

      const nextShowControls = activeView === 'viewA' ? viewAControls : viewBControls
      const nextHideControls = activeView === 'viewA' ? viewBControls : viewAControls
      const viewAHeight = (viewARef.current?.getBoundingClientRect()?.height || 0) + 2 * PANEL_PADDING
      const viewBHeight = (viewBRef.current?.getBoundingClientRect()?.height || 0) + 2 * PANEL_PADDING
      containerControls.start({
        height: activeView === 'viewA' ? viewAHeight : viewBHeight,
        transition: { duration: hasMounted ? 0.4 : 0 },
      })
      await nextHideControls.start({
        opacity: 0,
        position: 'absolute',
        zIndex: 0,
        transition: { duration: hasMounted ? 0.2 : 0 },
      })
      await nextShowControls.start({
        opacity: 1,
        position: 'inherit',
        zIndex: 1,
        transition: { duration: hasMounted ? 0.2 : 0 },
      })
      containerControls.set({ height: 'auto' })
      containerControls.set({ overflow: 'hidden' })

      if (!hasMounted) {
        setHasMounted(true)
      }
    }
    animate()
  }, [activeView, containerControls, viewAControls, viewBControls, hasMounted])

  return (
    <Box sx={{ paddingTop: 36 }}>
      <motion.div
        style={{ margin: '0 -15px', padding: '0 15px', zIndex: 1 }}
        ref={containerRef}
        animate={containerControls}
        initial={{ height: 0 }}
      >
        <FormPanel sx={{ height: '100%' }}>
          <motion.div ref={viewARef} initial={{ opacity: 0 }} animate={viewAControls} style={{ width: '100%' }}>
            {viewA}
          </motion.div>
          <motion.div ref={viewBRef} initial={{ opacity: 0 }} animate={viewBControls} style={{ width: '100%' }}>
            {viewB}
          </motion.div>
        </FormPanel>
      </motion.div>
    </Box>
  )
}
