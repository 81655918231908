import React from 'react'
import { FlexColumnLayout, Text, Button, ButtonTypeEnum, theme, Panel } from '@northone/ui'
import { SxStyleProp } from 'rebass'

interface IFormProps {
  title: string
  buttonText?: string
  children: React.ReactNode
  buttonType?: ButtonTypeEnum
  buttonDisabled?: boolean
  sx?: SxStyleProp
  containerSx?: SxStyleProp
  onButtonClick?: () => void
}

export const FORM_BUTTON_WIDTH = '288px'

export const FormLayout: React.FC<IFormProps> = ({
  title,
  buttonText = 'Continue',
  buttonType = ButtonTypeEnum.PRIMARY_GOLD,
  buttonDisabled = false,
  children,
  sx,
  containerSx,
  onButtonClick,
}) => {
  return (
    <FormContainer containerSx={containerSx} sx={sx}>
      <Text sx={{ margin: ['15px 0', '25px 0', '25px 0'] }} tag={['h3Serif', 'h2', 'h2']}>
        {title}
      </Text>
      <FormPanel>
        <form
          onKeyDown={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault()
            }
          }}
        >
          {children}
        </form>
        {onButtonClick && (
          <Button
            sx={{ marginTop: '20px', width: ['100%', '100%', FORM_BUTTON_WIDTH] }}
            type={buttonType}
            disabled={buttonDisabled}
            onClick={() => onButtonClick()}
          >
            {buttonText}
          </Button>
        )}
      </FormPanel>
    </FormContainer>
  )
}

interface IFormPanel {
  showOnPanelOnMobile?: boolean
  sx?: SxStyleProp
  onClick?: () => void
}

export const PANEL_PADDING = 25

export const FormPanel: React.FC<IFormPanel> = ({ children, showOnPanelOnMobile, sx, onClick }) => (
  <Panel
    sx={{
      borderRadius: [showOnPanelOnMobile ? '8px' : 0, '8px'],
      boxShadow: [
        showOnPanelOnMobile ? '0px 3px 12px rgba(0, 0, 0, 0.12)' : 'none',
        '0px 3px 12px rgba(0, 0, 0, 0.12)',
      ],
      marginBottom: 12,
      padding: [showOnPanelOnMobile ? PANEL_PADDING : '0px', PANEL_PADDING],
      ...sx,
    }}
    onClick={onClick}
  >
    <FlexColumnLayout
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      {children}
    </FlexColumnLayout>
  </Panel>
)

interface IFormContainerProps {
  containerSx?: SxStyleProp
  sx?: SxStyleProp
}

const formPadding = {
  top: [65, 65, 65, 30],
  bottom: [50],
}

export const FormContainer: React.FC<IFormContainerProps> = ({ containerSx, sx, children }) => (
  <FlexColumnLayout
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      paddingX: ['20px', '20px', 0],
      paddingTop: formPadding.top,
      paddingBottom: formPadding.bottom,
      backgroundColor: [theme.colors.ui7, theme.colors.ui7, theme.colors.ui7, theme.colors.gold10, theme.colors.gold10],
      ...containerSx,
    }}
  >
    <FlexColumnLayout
      sx={{
        width: ['100%', '100%', '660px'],
        maxWidth: '660px',
        ...sx,
      }}
    >
      {children}
    </FlexColumnLayout>
  </FlexColumnLayout>
)
