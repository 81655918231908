import React from 'react'
import { Box } from 'rebass'
import { Dropdown, TextInput } from '@northone/ui'
import { errorMessages } from '../utils/error-messages'
import { ResponsiveColumnToRowLayout } from '../layouts/responsive-column-to-row'
import { americanStates } from '../utils/constants'
import { regex } from '../utils/regex'
import { IAddressState, IRootState } from '../redux/state'
import { useSelector } from 'react-redux'
import { addressHasUpdated } from '../redux/selector'

interface IAddressFieldsProps {
  setAddress: (address: IAddressState) => void
  address: IAddressState
  fieldBackgroundColor?: string
  required?: boolean
}

interface IAddressInputs {
  name: keyof IAddressState
  label: string
  value: string
  getErrorMessage: (value: string) => string | undefined
  placeholder?: string
}

export const AddressFields: React.FC<IAddressFieldsProps> = ({
  address,
  setAddress,
  fieldBackgroundColor,
  required,
}) => {
  const isShowingErrors = useSelector<IRootState, boolean>(state => state.isShowingErrors)

  const { city, state, streetAddress, suite, zipCode } = address
  const addressInputs: Array<IAddressInputs[]> = [
    [
      {
        name: 'streetAddress',
        label: 'Street address',
        value: streetAddress,
        getErrorMessage: value => errorMessages.streetAddress(value),
      },
      {
        name: 'suite',
        label: 'Suite, floor or unit',
        value: suite,
        getErrorMessage: value => errorMessages.suite(value),
      },
    ],
    [
      {
        name: 'city',
        label: 'City',
        value: city,
        getErrorMessage: value => errorMessages.city(value),
      },
      {
        name: 'state',
        label: 'State',
        value: state,
        placeholder: 'Select state',
        getErrorMessage: value => (!value ? 'Required' : ''),
      },
    ],
    [
      {
        name: 'zipCode',
        label: 'Zip code',
        value: zipCode,
        getErrorMessage: value => errorMessages.zipCode(value),
      },
    ],
  ]

  return (
    <Box sx={{ marginTop: '10px' }}>
      {addressInputs.map((inputs, index) => (
        <ResponsiveColumnToRowLayout
          key={index}
          sx={{
            width: inputs.length === 1 ? ['100%', '100%', '50%'] : '100%',
            marginTop: inputs.length === 1 ? ['20px', '20px', 0] : undefined,
          }}
        >
          {inputs.map((addressField, index: number) =>
            addressField.name === 'state' ? (
              <Dropdown
                key={index}
                options={americanStates}
                selectedValue={state}
                label={addressField.label}
                placeholder={addressField.placeholder || addressField.label}
                onSelect={(value: string) => {
                  setAddress({ ...address, [addressField.name]: value })
                }}
                errorMessage={
                  isShowingErrors && (addressHasUpdated(address) || required)
                    ? addressField.getErrorMessage(addressField.value)
                    : undefined
                }
                maxHeight={[300]}
                backgroundColor={fieldBackgroundColor}
              />
            ) : (
              <TextInput
                backgroundColor={fieldBackgroundColor}
                autoFocus={false}
                key={index}
                label={addressField.label}
                value={addressField.value}
                placeholder={addressField.label}
                onChange={(value: string) => {
                  setAddress({
                    ...address,
                    [addressField.name]: addressField.name === 'zipCode' ? value.replace(regex.notNumber, '') : value,
                    ...(addressField.name !== 'suite' && { coordinates: null, description: '' }),
                  })
                }}
                containerSx={index === 0 ? { marginRight: ['0', '0', '15px'] } : undefined}
                maxLength={addressField.name === 'zipCode' ? 5 : undefined}
                errorMessage={
                  isShowingErrors && (addressHasUpdated(address) || required)
                    ? addressField.getErrorMessage(addressField.value)
                    : undefined
                }
              />
            ),
          )}
        </ResponsiveColumnToRowLayout>
      ))}
    </Box>
  )
}
