import { FlexColumnLayout, Text } from '@northone/ui'
import React, { useEffect, useState } from 'react'
import { Box, Image } from 'rebass'
import { FormContainer } from '../../layouts/form'

import Confetti from 'react-dom-confetti'
import { WaveBackground } from '../../layouts/wave-background'
import { theme } from '../../layouts/theme'
import { images } from '../../assets/images'

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 150,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}

export const SuccessScreen: React.FC = () => {
  const [confettiActive, setConfettiActive] = useState(false)
  useEffect(() => {
    setConfettiActive(true)
  }, [])
  return (
    <>
      <WaveBackground />
      <FormContainer containerSx={{ backgroundColor: 'transparent', height: '100vh', overflowY: 'hidden' }}>
        <Box sx={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Confetti config={config} active={confettiActive} />
          </Box>
          <Image src={images.airplanes} sx={{ width: 150, marginBottom: '8px' }} />
          <FlexColumnLayout>
            <Text sx={{ marginBottom: '8px' }} tag="h2">
              {"Yay! It's done"}
            </Text>
            <Text sx={{ color: theme.colors.ui2 }} tag="body-large">
              Feel free to close this window
            </Text>
          </FlexColumnLayout>
        </Box>
      </FormContainer>
    </>
  )
}
