import React, { useState, useEffect } from 'react'
import { FlexColumnLayout } from '@northone/ui'
import { ThemeProvider } from 'emotion-theming'
import { theme } from './layouts/theme'
import { Amplify } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { AWSConfig } from './core/aws-config'
import { WaveBackground } from './layouts/wave-background'
import { NorthOneCenteredLogo } from './layouts/northone-logo'
import './app.css'
import { Box } from 'rebass'
import { FormPage } from './pages/form/form-page'
import { NorthOneLogo } from './components/logo'
import { BulkReject } from './pages/bulk-reject'

Amplify.configure({
  Auth: AWSConfig,
})

const BULK_REJECT_PATHNAME = '/applications/reject'

export const APP_CONTAINER_ID = 'app-container'

export const App = () => {
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<{}>()
  const pathname = window.location.pathname

  const shouldShowBulkRejectPage = pathname === BULK_REJECT_PATHNAME || pathname === `${BULK_REJECT_PATHNAME}/`

  const page = shouldShowBulkRejectPage ? <BulkReject /> : <FormPage />

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <ThemeProvider theme={theme}>
      <Box id={APP_CONTAINER_ID} sx={{ height: '100vh', overflow: 'scroll' }}>
        <NorthOneLogo />
        {page}
      </Box>
    </ThemeProvider>
  ) : (
    <>
      <WaveBackground />
      <FlexColumnLayout>
        <NorthOneCenteredLogo />
        <AmplifyAuthenticator usernameAlias="email" style={{ marginTop: '-150px' }}>
          <AmplifySignIn
            slot="sign-in"
            usernameAlias="email"
            hideSignUp
            style={{ fontFamily: 'Graphik' }}
            formFields={[
              {
                type: 'email',
                label: 'Email',
                required: true,
              },
              {
                type: 'password',
                label: 'Password',
                required: true,
              },
            ]}
          />
        </AmplifyAuthenticator>
      </FlexColumnLayout>
    </>
  )
}
