import { LongTextInput, MaskTextInput, Text, TextInput } from '@northone/ui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'rebass'
import { AddButton } from '../../components/add-button'
import { AddressFields } from '../../components/address-fields'
import { ExpandingForm } from '../../layouts/expanding-form'
import { ResponsiveColumnToRowLayout } from '../../layouts/responsive-column-to-row'
import { reduxActions } from '../../redux/actions'
import { IAddressState, IRootState } from '../../redux/state'
import { errorMessages } from '../../utils/error-messages'
import { IndustrySelect } from './industry-select'

export const BusinessFields: React.FC = () => {
  const businessName = useSelector<IRootState, string>(state => state.businessName)
  const dba = useSelector<IRootState, string>(state => state.dba)
  const businessPhone = useSelector<IRootState, string>(state => state.businessPhone)
  const description = useSelector<IRootState, string>(state => state.businessDescription)
  const businessAddress = useSelector<IRootState, IAddressState>(state => state.businessAddress)
  const dispatch = useDispatch()
  const isShowingErrors = useSelector<IRootState, boolean>(state => state.isShowingErrors)

  const [isEditing, setIsEditing] = useState(false)

  return (
    <ExpandingForm
      viewA={<AddButton onClick={() => setIsEditing(true)} text="Edit business" />}
      activeView={isEditing ? 'viewB' : 'viewA'}
      viewB={
        <form
          onKeyDown={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault()
            }
          }}
        >
          <Box>
            <Text tag="body-large-bold" sx={{ marginBottom: 10 }}>
              Business name
            </Text>
            <ResponsiveColumnToRowLayout sx={{ width: '100%' }}>
              <TextInput
                autoFocus={false}
                placeholder="Legal business name"
                value={businessName}
                onChange={v => dispatch(reduxActions.setBusinessName(v))}
                label={'Legal business name'}
                containerSx={{ width: ['100%', '100%', '50%'], paddingRight: [0, 0, 10] }}
                errorMessage={isShowingErrors && businessName ? errorMessages.businessName(businessName) : ''}
              />
              <TextInput
                placeholder={'DBA'}
                value={dba}
                onChange={v => dispatch(reduxActions.setDba(v))}
                label={'DBA'}
                autoFocus={false}
                containerSx={{ width: ['100%', '100%', '50%'], paddingLeft: [0, 0, 10] }}
                errorMessage={isShowingErrors && dba ? errorMessages.dba(dba || '') : ''}
              />
            </ResponsiveColumnToRowLayout>
            <Text tag="body-large-bold" sx={{ marginBottom: 10 }}>
              Business info
            </Text>
            <LongTextInput
              autoFocus={false}
              placeholder={'Business description'}
              value={description}
              onChange={v => dispatch(reduxActions.setDescription(v))}
              errorMessage={isShowingErrors && description ? errorMessages.bizDescription(description) : ''}
              label="Business description"
            />
            <MaskTextInput
              autoFocus={false}
              placeholder="XXX-XXX-XXXX"
              value={businessPhone}
              onChange={v => dispatch(reduxActions.setBusinessPhone(v))}
              maskType="PHONE_NUMBER"
              label="Business phone number"
              errorMessage={isShowingErrors && businessPhone ? errorMessages.phone(businessPhone) : ''}
            />
            <Box sx={{ marginBottom: 30 }}>
              <IndustrySelect />
            </Box>
            <Text tag="body-large-bold" sx={{ marginY: 10 }}>
              Business address
            </Text>
            <AddressFields address={businessAddress} setAddress={v => dispatch(reduxActions.setBusinessAddress(v))} />
          </Box>
        </form>
      }
    />
  )
}
