import { IKYBIndustry } from '../core/application-review/industries'
import { documentTypes } from '../utils/constants'

export interface IAddressState {
  streetAddress: string
  suite: string
  city: string
  state: string
  zipCode: string
}

const initialAddressState: IAddressState = {
  city: '',
  state: '',
  streetAddress: '',
  suite: '',
  zipCode: '',
}

export type TApplicationStatus = 'Approve' | 'Reject' | null

export const getRandomId = () => Math.random().toString(36).substr(2, 10)
export type TDocumentType = keyof typeof documentTypes

export interface IDocument {
  id: string
  type: TDocumentType | null
  address: IAddressState
  issuedBy: string
  docId: string
  issueDate: string
  expiryDate: string
  ownerId: string
  link: string
}

export const initialDocument: IDocument = {
  id: getRandomId(),
  address: initialAddressState,
  docId: '',
  expiryDate: '',
  issueDate: '',
  issuedBy: '',
  type: null,
  ownerId: '',
  link: '',
}

export interface IOwner {
  id: string
  personId: string
  firstName: string
  lastName: string
  dateOfBirth: string
  phone: string
  address: IAddressState
  isPrimaryContact: boolean
}

export const initialOwner: IOwner = {
  id: getRandomId(),
  personId: '',
  firstName: '',
  lastName: '',
  address: initialAddressState,
  dateOfBirth: '',
  phone: '',
  isPrimaryContact: false,
}

export interface IRootState {
  businessName: string
  dba: string
  businessDescription: string
  businessPhone: string
  businessAddress: IAddressState
  businessIndustry: IKYBIndustry | null
  note: string
  applicationStatus: TApplicationStatus
  owners: IOwner[]
  isShowingErrors: boolean
  submitCompleted: boolean
  documents: IDocument[]
}

export const initialRootState: IRootState = {
  businessAddress: initialAddressState,
  businessDescription: '',
  businessName: '',
  businessPhone: '',
  businessIndustry: null,
  dba: '',
  note: '',
  applicationStatus: null,
  owners: [initialOwner],
  isShowingErrors: false,
  submitCompleted: false,
  documents: [],
}
