import { ErrorBanner, FlexRowLayout, ListItem, LoadingIcon, Text, theme } from '@northone/ui'
import React from 'react'
import { Box, Flex } from 'rebass'
import { FormPanel } from '../../layouts/form'
import { getApplicationPathParameters } from '../../utils/helpers'
import { useApplicationState } from '../../utils/hooks'

const openAccountInLooker = (prn: string) =>
  window.open(`https://northone.cloud.looker.com/dashboards-next/115?Business%20ID=&Account%20Number=${prn}`, '_blank')

export const DuplicateSSNBlock: React.FC = () => {
  const { businessId, applicationId } = getApplicationPathParameters()
  const { data, loading, error } = useApplicationState({ businessId, applicationId })

  if (loading) {
    return (
      <FormPanel sx={{ marginTop: 36 }}>
        <LoadingIcon />
        <Text tag="h5" sx={{ textAlign: 'center', marginTop: 14 }}>
          Searching for accounts with same ssn...
        </Text>
      </FormPanel>
    )
  }

  if (error || !data) {
    return (
      <FormPanel sx={{ marginTop: 36 }}>
        <ErrorBanner
          text={error?.response?.status === 404 ? 'Application not found' : 'Failed to retrieve accounts with same SSN'}
        >
          <Text tag="label" color={theme.colors.error100}>
            {error ? error.message : 'Nothing was returned'}
          </Text>
        </ErrorBanner>
      </FormPanel>
    )
  }

  if (!data.reports.accountsWithSameSSN.length) {
    return (
      <FormPanel sx={{ marginTop: 36 }}>
        <Text tag={'body'} sx={{ textAlign: 'center', color: theme.colors.ui2 }}>
          No warnings to display ✓
        </Text>
      </FormPanel>
    )
  }

  return (
    <FormPanel sx={{ marginTop: 36 }}>
      <FlexRowLayout sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <FlexRowLayout sx={{ alignItems: 'center' }}>
          <span role="img" style={{ padding: 10, color: theme.colors.error100, fontSize: 16 }}>
            ⚠
          </span>
          <Text tag={'h4'}>Warning</Text>
        </FlexRowLayout>
      </FlexRowLayout>
      <Text tag={'label'} sx={{ marginBottom: 10 }}>
        This application contains duplicate SSNs
      </Text>
      <Box sx={{ flex: 1 }}>
        <Flex>
          <Box p={3} width={0.35}>
            <Text tag={'label-bold'}>PRN</Text>
          </Box>
          <Box p={3} width={0.15}>
            <Text tag={'label-bold'}>UBO</Text>
          </Box>
          <Box p={3} width={0.3}>
            <Text tag={'label-bold'}>Account Opened Date</Text>
          </Box>
        </Flex>
      </Box>

      {data.reports.accountsWithSameSSN.map(account => (
        <ListItem key={`${account.personId}-${account.prn}`} onClick={() => openAccountInLooker(account.prn)}>
          <Box sx={{ flex: 1 }}>
            <Flex>
              <Box p={3} width={0.35}>
                <Text tag={'body-bold'}>{account.prn}</Text>
              </Box>
              <Box p={3} width={0.15}>
                <Text tag={'body'}>{account.uboIndex}</Text>
              </Box>
              <Box p={3} width={0.3}>
                <Text tag={'body'}>{account.dateOpened}</Text>
              </Box>
              <Box p={3} width={0.2} sx={{ textAlign: 'right' }}>
                <Text tag={'label'}>Looker →</Text>
              </Box>
            </Flex>
          </Box>
        </ListItem>
      ))}
    </FormPanel>
  )
}
