import { Auth } from 'aws-amplify'

export const logout = async () => {
  try {
    await Auth.signOut({ global: true })
    window.location.reload()
  } catch (error) {
    console.log('error signing out: ', error)
  }
}
