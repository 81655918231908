import {
  AnimatePresence,
  DeleteButton,
  Dropdown,
  FlexColumnLayout,
  MaskTextInput,
  Text,
  TextInput,
  theme,
} from '@northone/ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'rebass'
import { AddressFields } from '../../components/address-fields'
import { ResponsiveColumnToRowLayout } from '../../layouts/responsive-column-to-row'
import { reduxActions } from '../../redux/actions'
import { IDocument, IRootState } from '../../redux/state'
import { americanStates } from '../../utils/constants'
import { errorMessages } from '../../utils/error-messages'
import { masks } from '../../utils/masks'
import { DocumentTypeDropdown } from './document-type-dropdown'
import * as z from 'zod'

interface IDocumentFieldsProps {
  id: string
  ownerId: string
}

export const DocumentFields: React.FC<IDocumentFieldsProps> = ({ id }) => {
  const document = useSelector<IRootState, IDocument | undefined>(state =>
    state.documents.find(document => document.id === id),
  )

  const [isVisible, setIsVisible] = useState(false)
  const isShowingErrors = useSelector<IRootState, boolean>(state => state.isShowingErrors)

  const dispatch = useDispatch()

  useEffect(() => {
    setIsVisible(true)
  }, [])

  if (!document) {
    return null
  }
  const updateDocument = (fields: Partial<IDocument>) => dispatch(reduxActions.updateDocument(id, fields))
  return (
    <AnimatePresence animation="fadeAndExpand">
      {isVisible && (
        <FlexColumnLayout
          sx={{
            backgroundColor: 'rgb(245, 245, 245)',
            paddingX: 20,
            borderRadius: '8px',
            paddingY: 20,
            position: 'relative',
            marginBottom: 20,
          }}
        >
          <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
            <DeleteButton
              onClick={() => {
                setIsVisible(false)
                setTimeout(() => dispatch(reduxActions.deleteDocument(id)), 1000)
              }}
            />
          </Box>
          <form
            onKeyDown={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault()
              }
            }}
          >
            <TextInput
              value={document.docId}
              label="Doc Id"
              placeholder="Doc Id"
              onChange={value => updateDocument({ docId: value })}
              containerSx={{ width: ['100%', '100%', '50%'] }}
              backgroundColor={theme.colors.ui7}
              errorMessage={isShowingErrors && !document.docId ? 'Required' : ''}
            />
            <TextInput
              value={document.link}
              label="Link"
              placeholder="Link"
              onChange={value => updateDocument({ link: value })}
              backgroundColor={theme.colors.ui7}
              errorMessage={isShowingErrors && !z.string().url().check(document.link) ? 'Enter valid url' : ''}
            />
            <ResponsiveColumnToRowLayout>
              <Box sx={{ paddingBottom: 16, display: 'flex', flex: 1 }}>
                <Dropdown
                  options={[{ text: 'United States of America', value: 'US' }, ...americanStates]}
                  selectedValue={document.issuedBy}
                  label={'Issued by'}
                  placeholder={'Issued by'}
                  onSelect={(value: string) => updateDocument({ issuedBy: value })}
                  maxHeight={[300]}
                  backgroundColor={theme.colors.ui7}
                  errorMessage={isShowingErrors && !document.issuedBy ? 'Required' : ''}
                />
              </Box>
              <Box sx={{ marginLeft: 16, paddingBottom: 16, display: 'flex', flex: 1 }}>
                <DocumentTypeDropdown selectedType={document.type} setType={v => updateDocument({ type: v })} />
              </Box>
            </ResponsiveColumnToRowLayout>
            <ResponsiveColumnToRowLayout>
              <MaskTextInput
                onChange={v => updateDocument({ issueDate: v })}
                mask={masks.date}
                placeholder={'MM/DD/YYYY'}
                value={document.issueDate}
                label="Issue date"
                containerSx={{ marginRight: 16 }}
                backgroundColor={theme.colors.ui7}
                errorMessage={isShowingErrors ? errorMessages.pastDate(document.issueDate) : ''}
              />
              <MaskTextInput
                onChange={v => updateDocument({ expiryDate: v })}
                mask={masks.date}
                placeholder={'MM/DD/YYYY'}
                value={document.expiryDate}
                label="Expiry date (optional)"
                backgroundColor={theme.colors.ui7}
                errorMessage={
                  isShowingErrors && document.expiryDate ? errorMessages.futureDate(document.expiryDate) : ''
                }
              />
            </ResponsiveColumnToRowLayout>
            <Text tag="body-bold" sx={{ color: theme.colors.ui2 }}>
              Address
            </Text>
            <AddressFields
              fieldBackgroundColor={theme.colors.ui7}
              address={document.address}
              setAddress={v => updateDocument({ address: v })}
              required
            />
          </form>
        </FlexColumnLayout>
      )}
    </AnimatePresence>
  )
}
