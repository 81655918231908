import React from 'react'
import { FlexRowLayout, SelectionControl, Text } from '@northone/ui'
import { theme } from '../layouts/theme'
import { Box, SxStyleProp } from 'rebass'

export interface ISelectorBoxProps {
  isSelected: boolean
  tabIndex?: number
  sx?: SxStyleProp
  onSelect: () => void
  onFocus?: () => void
  onBlur?: () => void
  title: string
}

export const SelectorBox: React.FC<ISelectorBoxProps> = ({
  isSelected,
  title,
  onSelect,
  sx,
  tabIndex,
  onFocus,
  onBlur,
}) => (
  <Box
    onFocus={onFocus}
    onBlur={onBlur}
    tabIndex={tabIndex}
    onClick={onSelect}
    sx={{
      width: ['100%', '100%', '180px', '185px', '195px', '195px'],
      height: ['80px', '80px', '100px'],
      backgroundColor: isSelected ? theme.colors.gold10 : theme.colors.ui6,
      borderRadius: '4px',
      padding: isSelected ? 0 : '1px',
      border: isSelected ? `3px solid ${theme.colors.gold100}` : `2px solid ${theme.colors.ui5}`,
      ':hover': {
        cursor: 'pointer',
      },
      display: 'flex',
      marginRight: '16px',
      marginBottom: ['10px', '10px', 0],
      ...sx,
    }}
  >
    <FlexRowLayout
      sx={{
        alignItems: 'center',
        justifyContent: ['flex-start', 'flex-start', 'space-evenly'],
        marginLeft: ['5%', '5%', '10%'],
      }}
    >
      <SelectionControl
        type={'radio'}
        selected={isSelected}
        onSelect={onSelect}
        containerSx={{
          paddingBottom: 0,
          display: 'block',
          marginRight: '10px',
        }}
      />
      <Text sx={{ width: '100%' }} tag="body-bold">
        {title}
      </Text>
    </FlexRowLayout>
  </Box>
)
