import React, { ChangeEvent, useEffect, useState } from 'react'
import Papa from 'papaparse'
import { Button } from '@northone/ui'
import { PageHeading } from '../components/PageHeading'
import { IBusinessApplication } from '../core/application-review/models/types'
import { FormContainer, FormPanel } from '../layouts/form'
import { submitBulkApplicationRejections } from '../core/application-review'
import { AxiosResponse } from 'axios'

const BusinessApplicationList = ({ businessApplications }: { businessApplications: IBusinessApplication[] }) => {
  if (!businessApplications.length) return <p>No business applications found</p>
  return (
    <table style={{ marginBottom: '1rem' }}>
      <thead>
        <tr>
          <th>Business ID</th>
          <th>Application ID</th>
        </tr>
      </thead>
      <tbody>
        {businessApplications.map((businessApplication, index) => (
          <tr key={index}>
            <td>{businessApplication.businessId}</td>
            <td>{businessApplication.applicationId}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const BulkReject = () => {
  const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([])
  const [bulkRejectResponse, setBulkRejectResponse] = useState<AxiosResponse<any>>()
  const [isMounted, setIsMounted] = useState(false)
  const [shouldSendRejectionEmails, setShouldSendRejectionEmails] = useState(true)

  useEffect(() => {
    setIsMounted(true)

    return () => setIsMounted(false)
  }, [])

  const onShouldSendRejectionEmailsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isBoxChecked = event.target.checked
    setShouldSendRejectionEmails(isBoxChecked)
  }

  const onSubmit = async () => {
    const response = await submitBulkApplicationRejections({
      sendRejectionEmails: shouldSendRejectionEmails,
      businessApplications,
    }).catch(error => alert(error))
    if (!isMounted) return
    response && setBulkRejectResponse(response)
  }

  const onCSVFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return
    const file = event.target.files[0]
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setBusinessApplications(results.data as IBusinessApplication[])
      },
    })
  }

  return (
    <FormContainer>
      <PageHeading>Application Bulk Reject</PageHeading>
      <FormPanel>
        <h4>
          Upload a CSV file with <code>businessId</code> and <code>applicationId</code> columns
        </h4>

        <fieldset style={{ border: 'none', padding: 'none', marginBottom: '1rem' }}>
          <label>
            <input
              type="checkbox"
              name="shouldSendRejectionEmails"
              checked={shouldSendRejectionEmails}
              accept=".csv"
              onChange={onShouldSendRejectionEmailsChange}
            />
            Send rejection emails
          </label>
          <p>
            <small>
              Note: if this is unchecked, this will block the business from receiving a rejection email in the future.
              For example, the business will not receive a rejection email if the application rejections fail and we try
              it again with the intention of sending rejection emails.
            </small>
          </p>
        </fieldset>
        <fieldset style={{ border: 'none', padding: 'none', marginBottom: '1rem' }}>
          <input type="file" name="file" accept=".csv" onChange={onCSVFileSelected} />
        </fieldset>

        {businessApplications.length > 0 && <BusinessApplicationList businessApplications={businessApplications} />}

        <Button onClick={onSubmit}>Submit</Button>
      </FormPanel>
      {bulkRejectResponse && (
        <FormPanel>
          <h3>Rejected Applications</h3>
          <BusinessApplicationList businessApplications={bulkRejectResponse.data.rejectedBusinessApplications} />

          <h3>Skipped Applications</h3>
          <BusinessApplicationList businessApplications={bulkRejectResponse.data.skippedBusinessApplications} />

          <h3>Errored Applications</h3>
          <BusinessApplicationList businessApplications={bulkRejectResponse.data.erroredBusinessApplications} />
        </FormPanel>
      )}
    </FormContainer>
  )
}
