import axios from 'axios'
import { config } from '../../utils/environment'
import { Auth } from 'aws-amplify'
import { ManualReviewRequestBody } from './models/manual-review'
import { IBusinessApplication } from './models/types'

const client = axios.create({
  baseURL: config.applicationReviewUrl,
})

client.interceptors.request.use(async config => {
  const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
  config.headers.Authorization = `Bearer ${accessToken}`
  config.headers['Content-Type'] = 'application/json'
  return config
})

client.interceptors.response.use(
  response => response,
  error => {
    console.error(error)
    return Promise.reject(error)
  },
)

export interface IGetApplicationForReviewRequest {
  businessId: string
  applicationId: string
}
interface IAccountWithSameSSN {
  personId: string
  uboIndex: string
  prn: string
  dateOpened: string
  active: boolean
}

export interface IGetApplicationForReviewResponse {
  id: string
  businessId: string
  userId: string
  submittedAt: string
  reports: {
    accountsWithSameSSN: IAccountWithSameSSN[]
  }
}

export const getApplicationForReview = async ({
  applicationId,
  businessId,
}: IGetApplicationForReviewRequest): Promise<IGetApplicationForReviewResponse> =>
  client.get(`businesses/${businessId}/applications/${applicationId}/review`).then(({ data }) => data)

interface ISubmitManualReviewApplicationParam {
  businessId: string
  applicationId: string
  requestBody: ManualReviewRequestBody
}

export const submitManualReviewApplication = async ({
  businessId,
  applicationId,
  requestBody,
}: ISubmitManualReviewApplicationParam) =>
  client.post(`businesses/${businessId}/applications/${applicationId}/manual-review-outcome`, requestBody)

export const submitBulkApplicationRejections = async (data: {
  sendRejectionEmails: boolean
  businessApplications: IBusinessApplication[]
}) => client.post('applications/bulk-reject', data)
