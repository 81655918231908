import {
  AnimatePresence,
  Button,
  ButtonTypeEnum,
  DeleteButton,
  ErrorBanner,
  MaskTextInput,
  SelectionControl,
  Text,
  TextInput,
  theme,
} from '@northone/ui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'rebass'
import { AddButton } from '../../components/add-button'
import { AddressFields } from '../../components/address-fields'
import { ExpandingForm } from '../../layouts/expanding-form'
import { FORM_BUTTON_WIDTH } from '../../layouts/form'
import { ResponsiveColumnToRowLayout } from '../../layouts/responsive-column-to-row'
import { reduxActions } from '../../redux/actions'
import { ownerHasUpdated, ownerDocumentTypesAreValid } from '../../redux/selector'
import { IDocument, IOwner, IRootState } from '../../redux/state'
import { errorMessages } from '../../utils/error-messages'
import { DocumentFields } from './document-fields'

interface IOwnerFieldsProps {
  id: string
}
export const OwnerFields: React.FC<IOwnerFieldsProps> = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false)
  const owner = useSelector<IRootState, IOwner | undefined>(state => state.owners.find(owner => owner.id === id))
  const ownerDocuments = useSelector<IRootState, IDocument[]>(state =>
    state.documents.filter(document => document.ownerId === id),
  )

  const numberOfPrimaryContacts = useSelector<IRootState, number>(
    state => state.owners.filter(owner => owner.isPrimaryContact).length,
  )
  const dispatch = useDispatch()
  const isShowingErrors = useSelector<IRootState, boolean>(state => state.isShowingErrors)

  if (!owner) {
    return null
  }
  const updateOwner = (fields: Partial<IOwner>) => dispatch(reduxActions.updateOwner(id, fields))

  return (
    <ExpandingForm
      viewA={
        <AddButton
          onClick={() => {
            setIsEditing(true)
            setTimeout(() => dispatch(reduxActions.addOwner()), 1000)
          }}
          text={`Edit UBO`}
        />
      }
      viewB={
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <DeleteButton onClick={() => dispatch(reduxActions.deleteOwner(id))} />
          </Box>
          <form
            onKeyDown={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault()
              }
            }}
          >
            <Text tag="body-large-bold" sx={{ marginBottom: 10 }}>
              UBO Identity (Required)
            </Text>
            <TextInput
              containerSx={{ width: ['100%', '100%', '50%'] }}
              value={owner.personId}
              placeholder="Person id"
              label="Person id"
              onChange={v => updateOwner({ personId: v })}
              autoFocus={false}
              errorMessage={
                isShowingErrors && !owner.personId && ownerHasUpdated(owner, ownerDocuments)
                  ? 'Must provide UBO id'
                  : ''
              }
            />
            <SelectionControl
              text="This is the primary contact"
              type="checkbox"
              selected={owner.isPrimaryContact}
              onSelect={() => updateOwner({ isPrimaryContact: !owner.isPrimaryContact })}
            />
            {isShowingErrors && numberOfPrimaryContacts > 1 && (
              <Text tag={'label-bold'} textColor={theme.colors.error100}>
                There should only be one primary contact
              </Text>
            )}
            <Text tag="body-large-bold" sx={{ marginBottom: 10, marginTop: '5px' }}>
              UBO Details
            </Text>
            <ResponsiveColumnToRowLayout>
              <TextInput
                value={owner.firstName}
                placeholder="First name"
                label="First name"
                onChange={v => updateOwner({ firstName: v })}
                containerSx={{ marginRight: 16 }}
                autoFocus={false}
                errorMessage={isShowingErrors && owner.firstName ? errorMessages.firstName(owner.firstName) : ''}
              />
              <TextInput
                value={owner.lastName}
                placeholder="Last name"
                label="Last name"
                onChange={v => updateOwner({ lastName: v })}
                autoFocus={false}
                errorMessage={isShowingErrors && owner.lastName ? errorMessages.lastName(owner.lastName) : ''}
              />
            </ResponsiveColumnToRowLayout>
            <ResponsiveColumnToRowLayout>
              <MaskTextInput
                label="Date of birth"
                placeholder={'MM/DD/YYYY'}
                value={owner.dateOfBirth}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                onChange={v => updateOwner({ dateOfBirth: v })}
                autoFocus={false}
                containerSx={{ marginRight: 16 }}
                errorMessage={isShowingErrors && owner.dateOfBirth ? errorMessages.birthdate(owner.dateOfBirth) : ''}
              />
              <MaskTextInput
                value={owner.phone}
                placeholder="XXX-XXX-XXXX"
                label="Phone number"
                maskType="PHONE_NUMBER"
                onChange={v => updateOwner({ phone: v })}
                autoFocus={false}
                errorMessage={isShowingErrors && owner.phone ? errorMessages.phone(owner.phone) : ''}
              />
            </ResponsiveColumnToRowLayout>
            <Text tag="body-large-bold" sx={{ marginBottom: 10, marginTop: '5px' }}>
              UBO Address
            </Text>
            <AddressFields address={owner.address} setAddress={v => updateOwner({ address: v })} />
          </form>
          <Text tag="body-large-bold" sx={{ marginBottom: 10 }}>
            Documents
          </Text>
          <AnimatePresence animation="fadeAndExpand">
            {isShowingErrors && !ownerDocumentTypesAreValid(ownerDocuments) && (
              <ErrorBanner
                text={
                  'The document types must of one of the following:\n• 1 primary\n• 2 secondary\n• 1 secondary & 1 tertiary'
                }
              />
            )}
          </AnimatePresence>
          {ownerDocuments.map(document => (
            <DocumentFields key={document.id} ownerId={id} id={document.id} />
          ))}
          <Button
            onClick={() => dispatch(reduxActions.addDocument(id))}
            type={ButtonTypeEnum.SECONDARY}
            sx={{ width: ['100%', '100%', FORM_BUTTON_WIDTH], marginTop: 10 }}
          >
            Add a document
          </Button>
        </Box>
      }
      activeView={isEditing ? 'viewB' : 'viewA'}
    />
  )
}
