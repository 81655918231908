import jsValidator from 'validator'
import { IAddressState } from '../redux/state'
import { DATE_INPUT_FORMAT, MINIMUM_CLIENT_AGE, americanStates } from './constants'
import { regex } from './regex'
import { DateTime } from 'luxon'
import moment from 'moment'
import phone from 'phone'

export const maxLengths = {
  businessName: 50,
  addressField: 30,
  name: 30,
}

export const validate = {
  maxLength: (text: string, limit: number) => text.trim().length <= limit,
  minLength: (text: string, minLength: number) => text.trim().length >= minLength,
  businessName: (businessName: string) =>
    validate.maxLength(businessName, maxLengths.businessName) && validate.minLength(businessName, 2),
  dba: (dba: string | undefined) => !dba || dba.length <= maxLengths.businessName,
  description: (description: string) => validate.required(description) && description.length > 16,
  phoneNumber: (phoneNumber: string) => {
    const cleanPhoneNumber = phoneNumber.replace(regex.notNumber, '')
    return Boolean(cleanPhoneNumber.length === 10 && phone(cleanPhoneNumber).length)
  },
  isValidDate: (date: string) => {
    return DateTime.fromFormat(date, DATE_INPUT_FORMAT).isValid
  },
  isNotFutureDate: (date: string) => {
    const differenceBetweenDateAndToday = moment().diff(moment(date, DATE_INPUT_FORMAT), 'days')
    return differenceBetweenDateAndToday >= 0
  },
  isFutureDate: (date: string) => {
    return moment(date, DATE_INPUT_FORMAT).isAfter(moment())
  },
  firstName: (firstName: string) =>
    Boolean(validate.required(firstName) && validate.maxLength(firstName, maxLengths.name)),
  lastName: (lastName: string) =>
    Boolean(validate.minLength(lastName, 2) && validate.maxLength(lastName, maxLengths.name)),
  isOver18: (birthDate: string) => {
    const age = moment().diff(moment(birthDate, DATE_INPUT_FORMAT), 'years')
    return age >= MINIMUM_CLIENT_AGE
  },
  isAfter1900: (date: string) => Number(date.slice(-4)) > 1900,
  birthdate: (birthdate: string) =>
    validate.isValidDate(birthdate) && validate.isOver18(birthdate) && validate.isAfter1900(birthdate),
  streetAddress: (streetAddress?: string | null) =>
    Boolean(streetAddress && validate.required(streetAddress) && streetAddress.length <= maxLengths.addressField),
  suite: (suite?: string | null) => !suite || validate.maxLength(suite, maxLengths.addressField),
  city: (city?: string | null) =>
    Boolean(city && validate.required(city) && validate.maxLength(city, maxLengths.addressField)),
  zipCode: (zipCode?: string | null) =>
    Boolean(zipCode && jsValidator.isPostalCode(zipCode, 'US') && zipCode.length === 5),
  state: (state?: string | null) => Boolean(state && americanStates.find(({ value }) => value === state)),
  address: (address?: IAddressState) =>
    Boolean(
      address &&
        validate.streetAddress(address.streetAddress) &&
        validate.suite(address.suite) &&
        validate.city(address.city) &&
        validate.state(address.state) &&
        validate.zipCode(address.zipCode),
    ),
  required: (value: string | undefined) => Boolean(value && value.trim()),
}
