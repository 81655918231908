import React from 'react'
import { Box, Image } from 'rebass'
import { images } from '../assets/images'
import { LogoutButton } from '../layouts/logout-button'

export const NorthOneLogo: React.FC = () => (
  <>
    <Box
      sx={{
        zIndex: 1,
        top: 30,
        left: 30,
        position: 'fixed',
        display: ['none', 'none', 'none', 'block', 'block'],
      }}
    >
      <Image
        src={images.icons.northOneLogoDesktop}
        sx={{
          display: ['none', 'none', 'block'],
        }}
      />
    </Box>
    <LogoutButton />
  </>
)
