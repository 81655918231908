import { Button, images, useWindowDimensions } from '@northone/ui'
import React from 'react'
import { logout } from '../core/auth-service'
import { MOBILE_BREAKPOINT, theme } from '../layouts/theme'

export const LogoutButton = () => {
  const { width } = useWindowDimensions()
  return (
    <Button
      textTag="body-bold"
      sx={{ width: ['50px', '50px', '117px'], position: 'absolute', top: 20, right: 30 }}
      style={{
        enabledStyle: {
          backgroundColor: [theme.colors.ui7, theme.colors.ui7, theme.colors.gold10],
          border: 0,
          textColor: theme.colors.ui2,
          ':hover': { backgroundColor: theme.colors.ui5 },
          ':active': { backgroundColor: theme.colors.ui4 },
        },
        disabledStyle: {
          opacity: 0,
          textColor: 'transparent',
        },
      }}
      iconSrc={images.icons.logout}
      onClick={() => logout()}
    >
      {width && width <= MOBILE_BREAKPOINT ? '' : 'Log out'}
    </Button>
  )
}
