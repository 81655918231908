import * as z from 'zod'

import { ISO8601Date, PersonID, ApplicationID } from './types'
import { Address, ApplicationAmendment } from './application'

export const ManualReviewOutcome = z.enum(['Approve', 'Reject'])
export type ManualReviewOutcome = z.infer<typeof ManualReviewOutcome>

export const BaseIDDocument = z.object({
  /**
   * The document ID/number (ex. passport number)
   */
  idNumber: z.string(),
  /**
   * State or country of issuance
   */
  issuedBy: z.string(),
  type: z.string(),
  personId: PersonID,
  address: Address,
  dateIssued: ISO8601Date,
  dateOfExpiry: ISO8601Date.optional(),
  referenceUrl: z.string().url(),
})

export type BaseIDDocument = z.infer<typeof BaseIDDocument>

export const IDDocument = BaseIDDocument.extend({
  id: z.string(),
  applicationId: ApplicationID,
})

export type IDDocument = z.infer<typeof IDDocument>

export const BaseManualReview = z.object({
  outcome: ManualReviewOutcome,
  note: z.string(),
})

export type BaseManualReview = z.infer<typeof BaseManualReview>

export const ManualReview = BaseManualReview.extend({
  applicationId: z.string(),
  agent: z.object({
    id: z.string().nonempty(),
    username: z.string().nonempty(),
  }),
})

export type ManualReview = z.infer<typeof ManualReview>

export const ManualReviewRequestBody = BaseManualReview.extend({
  applicationAmendment: ApplicationAmendment.optional(),
  documents: z.array(BaseIDDocument).optional(),
})

export type ManualReviewRequestBody = z.infer<typeof ManualReviewRequestBody>
