export const americanStates = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'District of Columbia', value: 'DC' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' },
]

export const MINIMUM_CLIENT_AGE = 18
export const MINIMUM_BIRTH_YEAR = 1900
export const DATE_INPUT_FORMAT = 'MM/dd/yyyy'
export const DATE_MONTH_YEAR_FORMAT = 'MM/yy'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'

export const documentTypes = {
  'Passport or Passport Card': 1,
  'Driver’s License': 1,
  'US Military ID': 1,
  'US Armed Forces ID': 1,
  'Permanent Resident Card': 1,
  'US Employment Authorization Card': 1,
  'Matricula Consular': 1,
  'State-Issued ID': 1,
  'US Border Crossing Card': 1,
  'Cedula ID': 1,
  'Social Security Card': 2,
  'Birth Certificate': 2,
  'Official US Military record of service': 2,
  'Marriage Certificate': 2,
  'Divorce Decree': 2,
  'State-Issued Permit or Learner’s Permit': 2,
  'State Issued Benefit Card': 2,
  'US Tax Form': 2,
  'Certificate of Citizenship': 2,
  'Medicare Card': 2,
  'Court Order for Legal Name Change': 2,
  'Municipal ID': 2,
  'Utility Bill': 3,
  'Financial Record': 3,
  'Bank, 401(k), or Investment/Brokerage Statement': 3,
  'College/University Statement': 3,
  'Mortgage Statement': 3,
  'Life, Health, or Other Insurance Statement': 3,
  'Medical Record': 3,
  'Pay Stub': 3,
  'Lease Agreement': 3,
  'Vehicle Registration Card': 3,
  'Voter’s Registration Card': 3,
  'Letter from US Citizen and Immigration Services': 3,
  'Letter from a State Department of Motor Vehicles': 3,
  'Letter from a US State or County Health and Human Social Services': 3,
  'Letter from the Social Security Administration': 3,
}
