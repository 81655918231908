import React from 'react'
import { Box, Image } from 'rebass'
import { images } from '../assets/images'

export const WaveBackground: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: -1,
      }}
    >
      <Image src={images.wave} sx={{ height: '100vh', width: '100vw', objectFit: 'cover' }} />
    </Box>
  )
}
